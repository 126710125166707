// eslint-disable-next-line
import Chart from "chart.js/auto";
import classNames from "classnames/dedupe";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import strings from "../l10n";

let MyChart = class MyChart extends React.Component {
  componentDidMount() {
    const { data, type, options } = this.props;
    const ctx = this.el.getContext("2d");
    this.chart = new Chart(ctx, { type, data, options });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;

    if (data) {
      this.chart.data = data;
      this.chart.update();
    }
  }

  componentWillUnmount() {
    this.chart.destroy();
  }

  render() {
    const { loading, className } = this.props;

    return (
      <div
        className={classNames("canvas h-100 overflow-auto", className, {
          loading: loading,
        })}
      >
        <canvas ref={(el) => (this.el = el)} />
      </div>
    );
  }
};

MyChart = connect((state) => {
  const {
    auth: {
      user: { dashboard_theme: dashboardTheme },
    },
  } = state;
  return {
    dashboardTheme,
  };
})(MyChart);

const getFormat = (span) => {
  switch (span) {
    case "day":
      return {
        input: "YYYY-MM-DD",
        output: "ddd, D MMM",
      };
    case "week":
      return {
        input: "YYYY-WW",
        output: `[${strings.components.charts.week.toLowerCase()}] WW`,
      };
    case "month":
      return {
        input: "YYYY-MM",
        output: "MMMM",
      };
    case "quarter":
      return {
        input: "YYYY-Q",
        output: `[${strings.components.charts.quarter.toLowerCase()}] Q`,
      };
    case "year":
      return {
        input: "YYYY",
        output: "YYYY",
      };
  }
};

export const formatDate = (date, span) => {
  const { input: inputFormat, output: outputFormat } = getFormat(span);
  return moment(date, inputFormat).format(outputFormat).split(",");
};

export default MyChart;
