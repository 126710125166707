import React from "react";
import { connect } from "react-redux";

import { DropdownList } from "./components/widgets";

import strings from "./l10n";

export const ROLES = {
  admin: "admin",
  moderator: "moderator",
  content_creator: "content_creator",
  user_manager: "user_manager",
  user: "user",
};

export const getUserRoles = ({ roles }) => {
  const isAdmin = roles.includes(ROLES.admin);
  const isContentCreator = roles.includes(ROLES.content_creator);
  const isUserManager = roles.includes(ROLES.user_manager);
  const isModerator = roles.includes(ROLES.moderator);

  const allRoles = Object.keys(ROLES);

  if (isAdmin) {
    return allRoles;
  }
  if (isModerator) {
    return allRoles.slice(1);
  }
  if (isContentCreator) {
    return [ROLES.content_creator, ROLES.user];
  }
  if (isUserManager) {
    return [ROLES.user_manager, ROLES.user];
  }
  return ROLES.user;
};

export const getRoleName = (role) => {
  switch (role) {
    case ROLES.user:
      return strings.pages.users.components.roles.user;
    case ROLES.moderator:
      return strings.pages.users.components.roles.moderator;
    case ROLES.admin:
      return strings.pages.users.components.roles.admin;
    case ROLES.user_manager:
      return strings.pages.users.components.roles.user_manager;
    case ROLES.content_creator:
      return strings.pages.users.components.roles.content_creator;
    default:
      return null;
  }
};

const hasRole = (user, role) => user.roles.indexOf(role) > -1;

const isAdmin = (user) => hasRole(user, ROLES.admin);

const isModerator = (user) => hasRole(user, ROLES.moderator);

const isUserManager = (user) => hasRole(user, ROLES.user_manager);

const isContentManager = (user) => hasRole(user, ROLES.content_creator);

export const getRole = (user) => {
  if (isAdmin(user)) return ROLES.admin;
  if (isModerator(user)) return ROLES.moderator;
  if (isUserManager(user)) return ROLES.user_manager;
  if (isContentManager(user)) return ROLES.content_creator;

  if (hasRole(user, ROLES.user)) return ROLES.user;
  return null;
};

export const canSetRoles = (user) => {
  if (isAdmin(user))
    return [
      ROLES.user,
      ROLES.moderator,
      ROLES.user_manager,
      ROLES.content_creator,
    ];
  if (!hasRole(user, ROLES.moderator)) return [];
  if (isModerator(user))
    return [
      ROLES.user,
      ROLES.moderator,
      ROLES.user_manager,
      ROLES.content_creator,
    ];
  if (isUserManager(user))
    return [ROLES.user, ROLES.user_manager, ROLES.content_creator];
  return [];
};

let UserRoles = ({ input: { onChange, value }, canChange, allRoles }) => {
  return (
    <DropdownList
      disabled={!canChange}
      onChange={onChange}
      data={allRoles}
      textField={(item) => getRoleName(item)}
      value={value}
    />
  );
};

UserRoles = connect((state) => {
  const { user: currentUser } = state.auth;
  const { currentUser: targetUser } = state.users;

  const allRoles = canSetRoles(currentUser);
  const targetUserRole = getRole(targetUser);

  return {
    canChange: !targetUserRole || allRoles.indexOf(targetUserRole) > -1,
    allRoles: allRoles,
    userRole: targetUserRole,
  };
})(UserRoles);

export default UserRoles;
