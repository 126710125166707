import { paginationActions, requestActions } from "./utils";

export const TOPICS = requestActions("Topics/List");
export const TOPICS_ALL = requestActions("Topics/List/All");
export const PAGINATION = paginationActions("Topics/Paginate");
export const SELECT = "Topics/Select";
export const SELECT_ALL = "Topics/Select/All";
export const SHOW_COLUMNS = "Topics/ShowColumns";
export const TOPICS_PUBLISH = requestActions("Topics/Publish");
export const TOPICS_UNPUBLISH = requestActions("Topics/Unpublish");
export const TOPICS_DELETE = requestActions("Topics/Delete");
export const TOPIC = requestActions("Topic/Get");
export const TOPIC_SUBMIT = requestActions("Topic/Submit");
export const TOPIC_RESET = "Topic/Reset";
export const TOPIC_UPDATE = requestActions("Topic/Update");
export const TOPIC_CREATE = requestActions("Topic/Create");
export const TOPIC_IMPORT = requestActions("Topic/Import");
export const TOPIC_PUBLISH = requestActions("Topic/Publish");
export const TOPIC_UNPUBLISH = requestActions("Topic/Unpublish");
export const TOPIC_CLONE = requestActions("Topic/Clone");
export const TOPIC_DELETE = requestActions("Topic/Delete");
export const TOPIC_CONVERT = requestActions("Topic/Convert");
export const SHOW_CLONE_MODAL = "Topics/Modals/Clone/Show";
export const HIDE_CLONE_MODAL = "Topics/Modals/Clone/Hide";
export const SHOW_PUBLISH_MODAL = "Topics/Modals/Publish/Show";
export const HIDE_PUBLISH_MODAL = "Topics/Modals/Publish/Hide";
export const SHOW_CONVERT_MODAL = "Topics/Modals/Convert/Show";
export const HIDE_CONVERT_MODAL = "Topics/Modals/Convert/Hide";
export const TOPIC_SET_TAGS = "Topic/Tags/Set";
export const META_TOPIC = requestActions("Topic/Meta/Get");
export const META_TOPIC_ADD_TRANSLATION = "Topic/Meta/AddTranslation";
export const META_TOPIC_REMOVE_TRANSLATION = "Topic/Meta/RemoveTranslation";
export const META_TOPIC_RESET = "Topic/Meta/Reset";
export const LOCALIZATION = requestActions("Topic/Localization/List");
export const LOCALIZATION_CREATE = requestActions("Topic/Localization/Create");
export const LOCALIZATION_UPDATE = requestActions("Topic/Localization/Update");
export const LOCALIZATION_DELETE = requestActions("Topic/Localization/Delete");
export const LOCALIZATION_RESET = "Topic/Localization/List/Reset";
export const LOCALIZATION_ADD = "Topic/Localization/List/Add";
export const LOCALIZATION_BASE_SET = "Topic/Localization/Base/Set";
export const LOCALIZATION_REMOVE = "Topic/Localization/List/Remove";
export const LOCALIZATION_SET_TAGS = "Topic/Localization/Tags/Set";
