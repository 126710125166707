import fetch from "lib/fetch";

import { baseUrl, formatListParams, jsonOrReject } from "./utils";

export const getQuestions = (topicId, args) => {
  const parseTags = (value) => value.map(({ id }) => id);
  const params = formatListParams(args, { filter_tags: parseTags });

  return fetch(`${baseUrl}/categories/${topicId}/questions` + params, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getQuestion = (questionId) => {
  return fetch(`${baseUrl}/questions/${questionId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getQuestionTranslations = (questionId) => {
  return fetch(`${baseUrl}/questions/${questionId}/translations`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const updateQuestion = (questionId, formData) => {
  return fetch(`${baseUrl}/questions/${questionId}`, {
    method: "PUT",
    body: formData,
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const translateQuestion = (questionId, language) => {
  return fetch(
    `${baseUrl}/questions/${questionId}/auto-translate/${language}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
    },
  ).then(jsonOrReject);
};

export const bulkTranslateQuestions = (questionIds, languages) => {
  return fetch(`${baseUrl}/questions/bulk/auto-translate`, {
    method: "POST",
    body: JSON.stringify({ ids: questionIds, languages }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const patchQuestion = (questionId, formData) => {
  return fetch(`${baseUrl}/questions/${questionId}`, {
    method: "PATCH",
    body: formData,
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const createQuestion = (topicId, formData) => {
  return fetch(`${baseUrl}/categories/${topicId}/questions`, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const createMultiLanguageQuestion = (topicId, formData) => {
  return fetch(`${baseUrl}/meta_categories/${topicId}/questions`, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const publishQuestion = (questionId, data) => {
  return fetch(`${baseUrl}/questions/${questionId}/publish`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  }).then(jsonOrReject);
};

export const publishMetaQuestion = (questionId, data) => {
  return fetch(`${baseUrl}/meta_questions/${questionId}/publish`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  }).then(jsonOrReject);
};

export const unpublishQuestion = (questionId, data) => {
  return fetch(`${baseUrl}/questions/${questionId}/unpublish`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  }).then(jsonOrReject);
};

export const unpublishMetaQuestion = (questionId, data) => {
  return fetch(`${baseUrl}/meta_questions/${questionId}/unpublish`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  }).then(jsonOrReject);
};

export const deleteQuestion = (questionId, data) => {
  return fetch(`${baseUrl}/questions/${questionId}`, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  }).then(jsonOrReject);
};

export const getQuestionComments = (questionId, args) => {
  const params = formatListParams(args);
  return fetch(`${baseUrl}/questions/${questionId}/comments` + params, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const createQuestionComment = (questionId, data) => {
  return fetch(`${baseUrl}/questions/${questionId}/comments`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const deleteQuestionComment = (questionId, commentId) => {
  return fetch(`${baseUrl}/questions/${questionId}/comments/${commentId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const getMetaQuestionComments = (questionId, args) => {
  const params = formatListParams(args);
  return fetch(`${baseUrl}/meta_questions/${questionId}/comments` + params, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then(jsonOrReject);
};

export const createMetaQuestionComment = (questionId, data) => {
  return fetch(`${baseUrl}/meta_questions/${questionId}/comments`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const deleteMetaQuestionComment = (questionId, commentId) => {
  return fetch(
    `${baseUrl}/meta_questions/${questionId}/comments/${commentId}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
      },
    },
  ).then(jsonOrReject);
};

export const bulkCopyQuestions = (questionIds, destinationTopic) => {
  return fetch(`${baseUrl}/questions/bulk/copy/${destinationTopic}`, {
    method: "POST",
    body: JSON.stringify({ ids: questionIds }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkMoveQuestions = (questionIds, destinationTopic) => {
  return fetch(`${baseUrl}/questions/bulk/move/${destinationTopic}`, {
    method: "POST",
    body: JSON.stringify({ ids: questionIds }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkCopyMultiLanguageQuestions = (
  questionIds,
  destinationTopic,
) => {
  return fetch(`${baseUrl}/meta_questions/bulk/copy/${destinationTopic}`, {
    method: "POST",
    body: JSON.stringify({ ids: questionIds }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkMoveMultiLanguageQuestions = (
  questionIds,
  destinationTopic,
) => {
  return fetch(`${baseUrl}/meta_questions/bulk/move/${destinationTopic}`, {
    method: "POST",
    body: JSON.stringify({ ids: questionIds }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkPublishQuestions = (questionIds, data) => {
  return fetch(`${baseUrl}/questions/bulk/publish`, {
    method: "POST",
    body: JSON.stringify({ ids: questionIds, ...data }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkUnpublishQuestions = (questionIds, data) => {
  return fetch(`${baseUrl}/questions/bulk/unpublish`, {
    method: "POST",
    body: JSON.stringify({ ids: questionIds, ...data }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkDeleteQuestions = (questionIds, data) => {
  return fetch(`${baseUrl}/questions/bulk/delete`, {
    method: "POST",
    body: JSON.stringify({ ids: questionIds, ...data }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkAddTags = (data) => {
  return fetch(`${baseUrl}/questions/bulk/add_tags`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};

export const bulkRemoveTags = (data) => {
  return fetch(`${baseUrl}/questions/bulk/rem_tags`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(jsonOrReject);
};
