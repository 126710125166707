import {
  PAGINATION_ALL,
  PAGINATION_DEFAULT,
  TAGS_ALL,
  TAGS_DEFAULT,
} from "constants/tags.constants";
import { combineReducers } from "redux";

import makeList from "./list";
import pagination from "./pagination";

export default combineReducers({
  all: combineReducers({
    list: makeList(TAGS_ALL),
    pagination: pagination(PAGINATION_ALL),
  }),
  default: combineReducers({
    list: makeList(TAGS_DEFAULT),
    pagination: pagination(PAGINATION_DEFAULT),
  }),
});
