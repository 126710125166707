import * as constants from "constants/users.constants";
import * as api from "services/api/users";

import { createAction, handleErrors } from "./utils";

export const getUsers =
  (rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      users: { pagination },
    } = getState();
    dispatch(createAction(constants.USERS.REQUEST));
    return api
      .getUsers(pagination)
      .then((users) => dispatch(createAction(constants.USERS.SUCCESS, users)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USERS.FAILURE, error));
        if (rethrowOnError) throw error;
      });
  };

export const usersPage = (page) => (dispatch) => {
  dispatch(createAction(constants.PAGINATION.PAGE, page));
  return dispatch(getUsers());
};

export const usersPageSize = (size) => (dispatch) => {
  dispatch(createAction(constants.PAGINATION.PAGE_SIZE, size));
  return dispatch(getUsers());
};

export const usersOrderBy = (orderBy) => (dispatch) => {
  dispatch(createAction(constants.PAGINATION.ORDER, orderBy));
  return dispatch(getUsers());
};

export const usersSearch = (search) => (dispatch) => {
  dispatch(createAction(constants.PAGINATION.SEARCH, search));
  return dispatch(getUsers());
};

export const usersFilters = (filters) => (dispatch) => {
  dispatch(createAction(constants.PAGINATION.FILTERS, filters));
  return dispatch(getUsers());
};

export const usersSelect = (user) => (dispatch) => {
  return dispatch(createAction(constants.SELECT, user));
};

export const usersSelectAll = () => (dispatch) => {
  return dispatch(createAction(constants.SELECT_ALL));
};

export const usersShowColumns = (name) => (dispatch) => {
  return dispatch(createAction(constants.SHOW_COLUMNS, name));
};

export const usersDisable =
  (userId, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.USER_DISABLE.REQUEST));
    return api
      .updateUser(userId, { disabled: true })
      .then((user) => {
        return dispatch(createAction(constants.USER_DISABLE.SUCCESS, user));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USER_DISABLE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const usersEnable =
  (userId, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.USER_ENABLE.REQUEST));
    return api
      .updateUser(userId, { disabled: false })
      .then((user) => {
        return dispatch(createAction(constants.USER_ENABLE.SUCCESS, user));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USER_ENABLE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const getUser =
  (userId, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.USER.REQUEST));
    return api
      .getUser(userId)
      .then((user) => {
        return dispatch(createAction(constants.USER.SUCCESS, user));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USER.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const deleteUser =
  (userId, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.USER_DELETE.REQUEST));
    return api
      .deleteUser(userId)
      .then(() => {
        return dispatch(createAction(constants.USER_DELETE.SUCCESS));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USER_DELETE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const updateUser =
  (userId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.USER_UPDATE.REQUEST));
    return api
      .updateUser(userId, data)
      .then((user) => {
        return dispatch(createAction(constants.USER_UPDATE.SUCCESS, user));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USER_UPDATE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const getUserCertsInfo =
  (userId, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.USER_CERTS_INFO.REQUEST, { userId }));
    return api
      .getUserCertsInfo(userId)
      .then((info) => {
        return dispatch(
          createAction(constants.USER_CERTS_INFO.SUCCESS, { userId, info }),
        );
      })
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USER_CERTS_INFO.FAILURE, { userId }));
        if (rethrowOnError) throw error;
      });
  };

export const enableSelectedUsers =
  (rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      users: {
        list: { items: users },
      },
    } = getState();

    const selectedUsersIds = users
      .filter((user) => user.selected === true)
      .map((it) => it.id);

    dispatch(createAction(constants.USERS_ENABLE.REQUEST));

    return api
      .bulkEnableUsers(selectedUsersIds)
      .then(() => dispatch(createAction(constants.USERS_ENABLE.SUCCESS)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USERS_ENABLE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const disableSelectedUsers =
  (rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      users: {
        list: { items: users },
      },
    } = getState();

    const selectedUsersIds = users
      .filter((user) => user.selected === true)
      .map((it) => it.id);

    dispatch(createAction(constants.USERS_DISABLE.REQUEST));

    return api
      .bulkDisableUsers(selectedUsersIds)
      .then(() => dispatch(createAction(constants.USERS_DISABLE.SUCCESS)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USERS_DISABLE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const deleteSelectedUsers =
  (rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      users: {
        list: { items: users },
      },
    } = getState();

    const selectedUsersIds = users
      .filter((user) => user.selected === true)
      .map((it) => it.id);

    dispatch(createAction(constants.USERS_DELETE.REQUEST));
    return api
      .bulkDeleteUsers(selectedUsersIds)
      .then(() => dispatch(createAction(constants.USERS_DELETE.SUCCESS)))
      .catch((error) => {
        dispatch(handleErrors(error));
        dispatch(createAction(constants.USERS_DELETE.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const getUserFull =
  (userId, rethrowOnError = false) =>
  async (dispatch, getState) => {
    dispatch(createAction(constants.USER.REQUEST));

    const {
      company: {
        features: { external_training: externalTraining },
      },
    } = getState();

    try {
      const user = await api.getUser(userId);
      const { results: userGroups } = await api.getUserTeamsAll(userId);
      const { results: userCerts } = await api.getUserCertsAll(userId);
      const { results: userCompliance } = await api.getUserComplianceAll(userId);
      const { results: userTrainings } = externalTraining
        ? await api.getUserTrainingsAll(userId)
        : {};

      dispatch(
        createAction(constants.USER.SUCCESS, {
          ...user,
          groups: userGroups,
          certificates: userCerts,
          compliance: userCompliance,
          trainings: userTrainings || [],
        }),
      );
    } catch (error) {
      dispatch(createAction(constants.USER.FAILURE, error));
      if (rethrowOnError) throw error;
    }
  };

export const sendConfirmation = (userId) => () => {
  return api.sendConfirmation(userId);
};

export const requestConfirmationLink =
  (rethrowOnError = false) =>
  (dispatch, getState) => {
    const {
      users: {
        currentUser: { id: currentUserId },
      },
    } = getState();

    dispatch(createAction(constants.USER_CONFIRMATION_REQUEST.REQUEST));

    return api
      .requestConfirmationLink(currentUserId)
      .then((response) =>
        dispatch(
          createAction(constants.USER_CONFIRMATION_REQUEST.SUCCESS, response),
        ),
      )
      .catch((error) => {
        dispatch(createAction(constants.USER_CONFIRMATION_REQUEST.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const requestPasswordReset =
  (userId, data, rethrowOnError = false) =>
  (dispatch) => {
    dispatch(createAction(constants.USER_PASSWORD_RESET.REQUEST));
    return api
      .requestPasswordReset(userId, data)
      .then((response) =>
        dispatch(createAction(constants.USER_PASSWORD_RESET.SUCCESS, response)),
      )
      .catch((error) => {
        dispatch(createAction(constants.USER_PASSWORD_RESET.FAILURE));
        if (rethrowOnError) throw error;
      });
  };

export const resetCurrentUser = () => (dispatch) => {
  dispatch(createAction(constants.USER.RESET));
};
