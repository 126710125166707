import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/courses.constants";
import { combineReducers } from "redux";

import { sortAscending } from "../utils/arrays";
import pagination from "./pagination";
import { getListProps, selectAll, toggleSelect, unselectAll } from "./utils";
import {makeListReducer} from "./list";

const initialState = {
  loading: false,
  ready: false,
  items: [],
  itemsById: {},
  count: 0,
  columns: {
    thumbnail: true,
    name: true,
    description: true,
    languages: true,
    num_published_topics: true,
    num_topics: true,
    num_questions: true,
    num_users: true,
  },
  isPanelShown: false,
};

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.COURSES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.COURSES.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.COURSES.SUCCESS: {
      let { count, results: topics } = action.data;

      return {
        ...state,
        ...getListProps(topics),
        count: count,
        loading: false,
      };
    }
    case constants.SELECT: {
      const { items } = state;
      const { data: item } = action;
      const updatedItems = toggleSelect(item, items);

      return {
        ...state,
        ...getListProps(updatedItems),
      };
    }
    case constants.SELECT_ALL: {
      const { items, allSelected } = state;

      const updatedItems = allSelected ? unselectAll(items) : selectAll(items);

      return {
        ...state,
        ...getListProps(updatedItems),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const initialCourse = {
  loading: false,
  error: false,
  found: false,
  locked: false,
  editable: true,
  locked_order: true,
  tags: null,
  translations: [],
};

const currentCourse = (state = initialCourse, action) => {
  switch (action.type) {
    case constants.COURSE.REQUEST:
    case constants.COURSE_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.COURSE.FAILURE:
      return {
        ...state,
        loading: false,
        found: false,
        error: true,
      };
    case constants.COURSE.SUCCESS: {
      const { translations, name, description, language, ...course } =
        action.data;

      const processedTranslations = Object.keys(translations).map((lang) => ({
        language: lang,
        name: translations[lang].name,
        description: translations[lang].description,
        is_base: false,
      }));

      const processedCourse = {
        ...course,
        name,
        description,
        language,
        translations: [
          ...processedTranslations,
          {
            name,
            description,
            language,
            is_base: true,
          },
        ],
      };

      return {
        ...state,
        ...processedCourse,
        found: true,
        loading: false,
      };
    }
    case constants.COURSE_TOPICS_SET_ORDERING: {
      const status = action.data;

      return {
        ...state,
        locked_order: status,
      };
    }
    case constants.COURSE_UPDATE.SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.COURSE_UPDATE.FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }
    case constants.COURSE_RESET:
      return {
        ...initialCourse,
        found: false,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialCourse;
    default:
      return state;
  }
};

const sortTopicList = (topics) => {
  return [...topics].sort(
    (a, b) => a.order - b.order || sortAscending(b.title, a.title),
  );
};

const initialTopicList = {
  loading: false,
  ready: false,
  error: false,
  items: [],
  itemsById: {},
  count: 0,
  localList: [],
  changed: false,
};

const topicListReducer = (state = initialTopicList, action) => {
  switch (action.type) {
    case constants.COURSE_TOPICS.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
      };
    case constants.COURSE_TOPICS.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
        ready: false,
      };
    case constants.COURSE_TOPICS.SUCCESS: {
      let topics = action.data;
      topics = sortTopicList(topics);

      return {
        ...state,
        ...getListProps(topics),
        localList: topics,
        count: topics.length,
        loading: false,
        ready: true,
      };
    }
    case constants.COURSE_TOPICS_ADD: {
      const topic = action.data;

      const topicExists = state.localList.some(
        (it) => it.topic_id === topic.topic_id,
      );

      if (topicExists) return state;

      return {
        ...state,
        changed: true,
        localList: [
          ...state.localList,
          {
            ...topic,
            order: state.localList.length,
          },
        ],
      };
    }
    case constants.COURSE_TOPICS_SET_ORDER: {
      const { oldIndex, newIndex } = action.data;

      const item = state.localList[oldIndex];

      let newLocalList = [...state.localList];
      newLocalList.splice(oldIndex, 1);
      newLocalList.splice(newIndex, 0, item);
      newLocalList = newLocalList.map((it, idx) => ({ ...it, order: idx }));

      return {
        ...state,
        changed: true,
        localList: newLocalList,
      };
    }
    case constants.COURSE_TOPICS_REMOVE: {
      const topic = action.data;

      let newLocalList = state.localList.filter(
        (it) => it.topic_id !== topic.topic_id,
      );
      newLocalList = newLocalList.map((it, idx) => ({ ...it, order: idx }));

      return {
        ...state,
        changed: true,
        localList: newLocalList,
      };
    }
    case constants.COURSE_TOPICS_SET_ORDERING: {
      const ordering = action.data;

      let newLocalList = state.localList.map((it, idx) => ({
        ...it,
        order: ordering ? idx : -1,
      }));

      newLocalList = sortTopicList(newLocalList);

      return {
        ...state,
        changed: true,
        localList: newLocalList,
      };
    }
    case UNAUTHORIZED:
    case constants.COURSE_RESET:
      return initialTopicList;
    default:
      return state;
  }
};

export default combineReducers({
  list: makeListReducer(coursesReducer, "courses"),
  pagination: pagination(constants.PAGINATION, {
    orderBy: "title",
  }),
  current: combineReducers({
    course: currentCourse,
    topics: combineReducers({
      list: topicListReducer,
      pagination: pagination(constants.COURSE_TOPICS_PAGINATION),
    }),
  }),
});
