const en = {
  btnLoading: "Loading...",
  filterNotification: "You searched for {0}",
  general: {
    or: "or",
    next: "next",
    wait: "Please wait...",
    warning: "Warning!",
  },

  messages: {
    unknownError: "An error occurred. Please try again or contact support.",
    formError: "An error occurred. Please check form and try again",
    saved: "Saved",
    success: "Success!",
    textCopied: "Text copied to clipboard",
    textCopyError:
      "An error occurred while copying the text. Please select the text and copy it manually.",
    csrfError:
      "Invalid or missing CSRF token. Please try submitting your request again. If the issue persists, please contact support.",
  },

  labels: {
    beta: "Beta",
  },

  fields: {
    email: {
      placeholder: "E-mail address",
    },
    password: {
      placeholder: "Password",
      description: "At least 8 characters, with 1 capital and 1 number",
    },
    repeatPassword: {
      placeholder: "Repeat password",
    },
    domain: {
      errors: {
        invalid: "Missing or invalid domain name",
        duplicated: "Duplicated domain name",
      },
    },
    errors: {
      incorrectValue: "Incorrect value",
      fieldRequired: "Field is required",
      invalidDate: "Invalid date",
      overlappingDate: "Overlapping date",
    }
  },

  buttons: {
    downloadExcel: "Download XLSX",
    downloadCsv: "Download CSV",
    generate: "Generate",
    save: "Save",
    submit: "Submit",
    dismiss: "Dismiss",
    confirm: "Confirm",
    clear: "Clear",
    loading: "Loading...",
    addMore: "Add more",
    create: "Create",
    undo: "Undo",
    close: "Close",
    remove: "Remove"
  },

  pages: {
    activityLog: {
      activityLog: "Activity log",
      table: {
        columns: {
          activity: "Activity",
          user: "User",
          created: "Created",
        },
      },
    },
    auth: {
      clickToLogout: "Click here to logout",
      sso: {
        signIn: "Sign in to Knowingo{0}",
        ssoUnavailable:
          "Single Sign-On unavailable. Contact your administrator for more information.",
        redirect: {
          redirectionInProgress: "You will be redirected in {0} seconds.",
          redirectManually:
            "If you are not redirected automatically please click here.",
          pleaseWait: "Please wait",
        },
      },
      mfa: {
        authentication: {
          tokenInputLabel: "Authentication token",
          tokenInputPlaceholder:
            "Enter the 6-digit code that you see in the authenticator app",
          verify: "Verify",
        },
        setup: {
          multiFactorAuthentication: "Multi-factor authentication setup",
          step1: "Step 1: Download an authenticator app",
          step1Description:
            "Download and install any authenticator app from a store (Google Authenticator, Microsoft Authenticator, Authy). You can skip this step if you already have it installed.",
          step2: "Step 2: Scan QR-code",
          step2Description:
            "Open the Authenticator app scan the QR-code below to add Knowingo to the list.",
          step3: "Step 3: Enter token",
          step3Description:
            "Open the Authenticator app and enter generated token in the field below:",
          typeManually:
            'If you can not scan the QR-code, tap on "Enter a setup key" in the Authenticator app and use following configuration:',
          continue: "Continue",
          secret: "Secret",
          keyType: "Type of key",
          timeBased: "Time based",
          accountName: "Account name",
          showManualConfiguration:
            "Click here if you are unable to scan the QR-code",
        },
      },
      forgotPassword: {
        resetPassword: "Reset password",
        form: {
          successMessage:
            "Check your email. You will receive a password reset link within a few minutes. No email received? Please check if the submitted mail address is correct.",
          emailAddress: "E-mail address",
        },
        goBack: "Go back to login page",
      },
      login: {
        form: {
          emailAddress: "E-mail address",
          password: "Password",
          rememberMe: "Remember me",
          forgotPassword: "Forgot password?",
          login: "Login",
          ssoLogin: "Login with SSO",
        },
        signIn: "Sign in to Knowingo{0}",
      },
    },
    confirmation: {
      forms: {
        passwordReset: {
          header: "Reset password",
          enterPassword: "Enter new password",
          repeatPassword: "Repeat new password",
          errors: {
            notSamePasswords: "Passwords are not the same",
          },
          submit: "Save new password",
          passwordChanged: "Your password has been changed",
          goBack: "Go back to login page",
        },
        emailChange: {
          header: "Email address change",
          emailChangedTo: "Your email address has been changed to {0}",
          contactSupport:
            "If you have problem with logging in to your account contact with Knowingo support at {0}",
          goBack: "Go back to login page",
        },
        emailConfirmation: {
          emailConfirmed: "E-mail address has been confirmed",
        },
        passwordWasReset: "Your password was reset successfully!",
        loginToDashboard: "You can continue by logging into the {0}.",
        loginToApp:
          "If you were using the Knowingo Application you can continue playing by going back to the mobile application or by using the {0}",
        knowingoDashboard: "Knowingo Dashboard",
        knowingoWebApplication: "Knowingo Web Application",
      },
      errors: {
        invalidConfirmation: {
          header: "Invalid confirmation link",
          paragraph: "The url you entered is invalid or has expired.",
          contactAdministrator:
            "Please contact your administrator for further details.",
        },
        unknownError: {
          header: "An error occurred",
          contactSupport:
            "An error occurred and we could not process your request. Please contact support at {0}",
        },
      },
    },
    trainingConfirmation: {
      thankYouForFeedback: "Thank you for your feedback",
      giveFeedback: "Please let us know if you attended below:",
      yes: "Yes",
      no: "No",
    },
    signup: {
      errors: {
        outOfSeats: {
          header: "Out of seats",
          paragraph:
            "You cannot create an account, because it will exceed the maximum number of users allowed to join this organization on Knowingo",
          contactAdministrator:
            "Please contact your administrator for further details.",
        },
        invalidCode: {
          header: "Invalid signup link",
          paragraph: "The url you entered is invalid or has expired",
          contactAdministrator:
            "Please contact your administrator for further details.",
        },
        unknownError: {
          header: "An error occurred",
          contactAdministrator:
            "Please contact your administrator for further details.",
        },
        logoutRequired: {
          header: "Logout required",
          paragraph:
            "You are trying to access resource available only for new users. Log out and try again",
          logout: "Log out",
        },
        signupNotAllowed: {
          header: "Signup not allowed",
          paragraph:
            "This company has closed signup. If you want to join this company in Knowingo contact the administrator.",
        },
      },
      forms: {
        fillInFields: "Please fill in the fields below",
        personalInformation: "Personal information",
        accountInformation: "Account information",
        errors: {
          notSamePasswords: "Passwords are not the same",
        },
        firstName: "First name",
        lastName: "Last name",
        workEmailAddress: "Business e-mail address",
        password: "Password",
        repeatPassword: "Repeat password",
        getStarted: "Get started",
        consentCheckbox:
          "Check here to confirm that you have read and agreed to {0} and {1}",
        termsOfService: "Terms of service",
        privacyPolicy: "Privacy policy",
        allowedDomains:
          "For security reasons, only email addresses from approved domains are accepted. Contact your account administrator or Knowingo support for more details.",
      },
      accountActivated:
        "Your Knowingo account has been activated and is ready to go.",
      startLearning:
        "Now all you have to do is download the app and start learning!",
      confirmationSent: "We have just sent to you a confirmation email.",
      clickOnLink: "Click on the link inside and start learning!",
      usedEmail: "Email address used for registration:",
      step: "Step",
      createAccount: "Create your Knowingo account",
      downloadApp: "Download the Knowingo app",
      appLinks:
        "Click one of these links to visit your application store, or search for Knowingo in the store.",
      readyToPlay: "Ready to play",
      haveFunLearning: "Have fun learning!",
      broughtBy: "Brought to you by",
    },
    dashboard: {
      betaNotification: "You are using beta version of the Knowingo dashboard.",
      modals: {
        selectCompany: {
          changeCompany: "Change company",
          open: "Open",
          cancel: "Cancel",
        },
      },
    },
    enterpriseStatistics: {
      loading: "Loading",
      enterpriseStatistics: "Enterprise statistics",
      beta: "Beta",
      adoption: {
        adoption: "Adoption",
        adoptionPerGroup: "Adoption per group",
        joined: "Joined",
        joinedLastWeek: "Joined past 7 days",
        missing: "Missing",
        joinedStatus:
          "{0} users that have registered and played their first quiz.",
        joinedLastWeekStatus:
          "{0} users that have registered and played in the last 7 days. This overlaps with joined.",
        missingStatus:
          "{0} users are people that have been invited but haven’t registered their account yet.",
        status: "Status",
        loading: "Loading",
        error: "Error",
        noGroupSelected: "No group selected",
        noData: "No data",
        showMore: "Show more",
        email: "E-mail",
        firstName: "First name",
        lastName: "Last name",
        users: "Users",
      },
      certification: {
        certificates: "Certificates",
        certification: "Certification",
        certificationPerGroup: "Certification per group",
        fullyCertified: "Fully certified",
        onTrack: "On track",
        expired: "Expired",
        overdue: "Overdue",
        missing: "Missing",
        fullyCertifiedStatus: "{0} users have obtained a valid certificate.",
        onTrackStatus: "{0} users are going through their learning journey.",
        expiredStatus:
          "{0} users certificates have surpassed their validity time.",
        overdueStatus:
          "{0} users have missed the deadline for their mandatory topics.",
        missingStatus:
          "{0} users are people that have been invited but haven’t registered their account yet.",
        noGroupSelected: "No group selected",
        noData: "No data",
        showMore: "Show more",
        email: "E-mail",
        firstName: "First name",
        lastName: "Last name",
        topic: "Topic",
        status: "Status",
        downloadAll: "Download all",
      },
      experts: {
        initialKnowledgeScore: "Initial knowledge score",
        selectTopic: "Select topic",
        topic: "Topic",
        expert: "Expert",
        noData: "No data",
        yourExperts: "Your experts",
        loading: "Loading",
        error: "Error",
        collapse: "Collapse",
        expand: "Expand",
      },
      groupSelection: {
        description:
          "This will add a group based filter over all the statistics below and the excel download.",
        groupFilter: "Group filter",
      },
      misconception: {
        misconception: "Misconception",
        selectTopic: "Select topic",
        topic: "Topic",
        question: "Question",
        correctAnswer: "Correct answer",
        noData: "No data",
        error: "Error",
        loading: "Loading",
      },
      modal: {
        download: "Download",
        close: "Close",
      },
    },
    overview: {
      header: "Dashboard",
      cards: {
        answers: "Correct answers",
        users: "Active users",
        multiplier: "Knowledge multiplier",
      },
      charts: {
        knowledge: {
          header: "Knowledge",
          legend: {
            initialKnowledge: "Initial knowledge",
            currentKnowledge: "Current knowledge",
            potentialKnowledge: "Potential knowledge",
          },
          inputs: {
            groups: "All groups",
          },
        },
        trainingTime: {
          header: "Training time",
          legend: {
            minutes: "Minutes",
          },
          inputs: {
            groups: "All groups",
            topics: "All topics",
            topicsInLearningPlan: "All topics in learning plan",
          },
        },
        questionsMastered: {
          header: "Questions mastered",
          legend: {
            questionsMastered: "Questions mastered",
          },
          inputs: {
            groups: "All groups",
          },
        },
        learningProgress: {
          header: "User learning progress",
          legend: {
            users: "Users",
          },
          inputs: {
            groups: "All groups",
            topics: "All topics",
          },
        },
      },
      components: {
        dropdown: {
          default: "Default",
          companyDashboards: "Company dashboards",
          createNew: "Create new dashboard",
          copy: "Copy dashboard",
          edit: "Edit dashboard",
          delete: "Delete dashboard",
        },
        toolbar: {
          addWidget: "Add widget",
          settings: "Settings",
          save: "Save dashboard",
          cancel: "Cancel editing",
        },
        settings: {
          header: "Dashboard settings",
          dashboardName: "Dashboard name",
          companyDefault: "Company default",
        },
        widgets: {
          header: "Select widgets",
          add: "Add",
          remove: "Remove",
          charts: "Charts",
          knowledge: {
            header: "Knowledge",
            description:
              "See the initial, current, and potential knowledge of active Knowingo users here.",
            initialKnowledge:
              "initial knowledge (red) shows how much knowledge users had before they started to learn with Knowingo",
            currentKnowledge:
              "current knowledge (green) shows how much knowledge users have currently learned",
            potentialKnowledge:
              "potential knowledge (blue) is the amount of knowledge users could achieve based on what’s in their learning plan",
          },
          masteredQuestions: {
            header: "Mastered questions",
            description:
              "The total amount of questions that Knowingo has successfully taught your users!",
          },
          trainingTime: {
            header: "Training time",
            description:
              "This graph shows the amount of minutes played per selected timeframe, user group and learning plan.",
            minutes: "Minutes",
          },
          learningProgress: {
            header: "Learning progress",
            description:
              "This graph shows how much progress your employees have made in their learning plan. New users start at 0% and users who have completed their learning plan entirely can be seen in the 90-100% column.",
          },
          leaderBoard: {
            header: "Leaderboard",
            description:
              "Leaderboard shows your player base ranked based on Minutes Played, Most Certificates and their Expertise. You can filter these based on groups and topics. This widget shows which players are the best learners in your organisation.",
          },
          timeToCertify: {
            header: "Time to certify",
            description:
              "Time to certify shows the average time it takes for users to reach their certificate for each topic in minutes.",
            minutes: "Minutes",
          },
          knowledgeGain: {
            header: "Knowledge gain",
            description:
              "The Knowledge Gain graph shows how the knowledge gap in your population closes over time. The Knowledge Gain shows the amount of knowledge your players have gained during any amount of time.",
            knowledgeGain: "Knowledge gain",
            knowledgeDecrease: "Knowledge decrease",
          },
          certificateStatus: {
            header: "Certificate status",
            description:
              "This graph shows a breakdown of certificate statuses per topic. Use it to see how your users are progressing towards reaching their certificate.",
            isCertified: "Certified",
            isExpired: "Expired certificates",
            isMissing: "Unregistered users",
            isMissed: "Missed deadline",
            isOnTrack: "Within deadline",
            isUncertified: "Not certified",
            detailedView: "Simple/Detailed view",
          },
          questionAnalytics: {
            header: "Question analytics",
            description:
              "This widget shows an overview of how many questions have reached the warning thresholds on the different metrics: misconception, complexity, obviousness and error score.",
            metric: "Metric",
            questions: "Questions",
            highMisconception: "High misconception",
            highObviousness: "High obviousness",
            highComplexity: "High complexity",
            highErrorScore: "High error score",
          },
          adoption: {
            header: "Adoption",
            description:
              "This graph shows a breakdown of global adoption status of your population. This helps you identify whether or not your users have completed the registration process and have started playing their first quiz.",
            invited: "Invited",
            registered: "Registered",
            activated: "Activated",
          },
          overallCompliance: {
            header: "Overall compliance",
            compliant: "Compliant",
            notCompliant: "Not compliant",
            current: "Current",
            allTime: "All time",
          },
          error: {
            header: "An error occurred",
            reload: "Click here to reload",
          },
          noData: "No data to display"
        },
        dialog: {
          deleteDashboard: {
            header: "Delete dashboard",
            content: "Are you sure you want to delete dashboard {0}?",
          },
        },
      },
      messages: {
        dashboardDeleted: "Dashboard has been deleted",
        dashboardSaved: "Dashboard has been saved",
      },
    },
    profile: {
      form: {
        basicInformation: "Basic information",
        firstName: "First name",
        lastName: "Last name",
        email: "E-mail",
        passwordSettings: "Password settings",
        currentPassword: "Current password",
        newPassword: "New password",
        confirmNewPassword: "Confirm new password",
        languageSettings: "Language settings",
        dashboardLanguage: "Dashboard language",
        appLanguage: "App language",
        default: "Default",
        errors: {
          missingUppercase: "Password is missing uppercase character.",
          missingLowercase: "Password is missing lowercase character.",
          missingDigit: "Password is missing a digit.",
        },
      },
      profile: "My profile",
      api: {
        header: "API Settings",
        buttons: {
          showKey: "Show key",
          hideKey: "Hide key",
        },
        form: {
          generateKey: "Generate API Key",
          manageKeys: "Manage API access",
          keysDescription:
            "Generate a unique key to securely access our API. Please note that each user is allowed only one active API key at a time. To generate a new API key, simply click the button above. Your current active key, if any, will be invalidated upon generation of a new key.",
          value: "Value",
          expiration: "Expires at",
          deleteKey: "Delete API key",
          deleteConfirmationPrompt:
            "You are about to delete an API key. Any services currently using this key will lose access immediately. Please ensure you have updated all relevant services with an alternative key before proceeding with the deletion.",
        },
        dialogs: {
          delete: {
            header: "Invalidate key",
            confirm:
              "Current key will be invalidated. Do you want to continue?",
          },
        },
        rateLimits: "Rate limits",
        rateLimitDescription: "API rate limits are used to prevent abuse, ensure fair usage, and maintain system stability and performance. If you have any questions regarding API usage or rate limits are not sufficient please contact support at {0}.",
        userRateLimit: "User rate limit",
        companyRateLimit: "Company rate limit",
        requestsPerMinute: "requests per minute",
        docs: "OpenAPI documentation, which provides comprehensive information and specifications for interacting with API, can be found at {0}",
        authentication: "API authentication",
        authenticationDescription: "Subsequent requests to the API must include API key in the {0} header to authenticate the user and authorize access to the requested resources.",
        exampleRequest: "Example request",
        gettingStarted: "Getting started",
      },
    },
    users: {
      header: "Users",
      cards: {
        totalUsers: "Total users",
        availableSeats: "Available seats",
        registeredUsers: "Registered users",
        unregisteredUsers: "Unregistered users",
        onlineUsers: "Online users",
      },
      table: {
        messages: {
          usersActivated: "Selected users have been activated",
          usersDeactivated: "Selected users have been deactivated",
          invitationSent: "Invitation has been sent",
          invitationsSent: "Invitations have been sent",
        },
        controls: {
          inviteButton: "Invite",
          importButton: "Import",
          actionsButton: {
            label: "Actions",
            items: {
              activate: "Activate",
              deactivate: "Deactivate",
              resendInvitations: "Resend invitations",
              delete: "Delete",
            },
          },
          filtersDropdown: {
            registered: "Registered",
            unregistered: "Unregistered",
            enabled: "Enabled",
            disabled: "Disabled",
          },
        },
        columns: {
          select: "Select",
          firstname: "Firstname",
          lastname: "Lastname",
          email: "Email",
          learningProgress: "Learning progress",
          trainingTime: "Training minutes",
          certificationStatus: "Certification status",
          lastSeenOnline: "Last seen online",
          moderator: "Moderator",
          disabled: "Disabled",
          invitationDate: "Invited",
          invitationStatus: "Invitation status",
          actions: {
            edit: "Edit",
            activate: "Activate",
            deactivate: "Deactivate",
            delete: "Delete",
            resendInvitation: "Resend invitation",
          },
          sso: "SSO",
        },
        dialogs: {
          bulkDelete: {
            header: "Delete users",
            content: "Are you sure you want to delete selected users?",
            confirmationMessage: "Selected users have been deleted!",
          },
          activationConfirmation: {
            header: "Activate user",
            content: "Are you sure you want to activate user {0}?",
            confirmationMessage: "User has been activated!",
          },
          deactivationConfirmation: {
            header: "Deactivate user",
            content: "Are you sure you want to deactivate user {0}?",
            confirmationMessage: "User has been deactivated!",
          },
          deleteConfirmation: {
            header: "Delete user",
            content: "Are you sure you want to delete user {0}?",
            confirmationMessage: "User has been deleted!",
          },
        },
      },
      components: {
        certification: {
          certified: "Certified",
          notCertified: "Not yet certified",
          expired: "Expired",
          expires: "Expires",
          columns: {
            mandatoryContent: "Mandatory content",
            optionalContent: "Recommended content",
            complianceContent: "Compliance content",
            status: "Status",
            startDate: "Start date",
            endDate: "End date",
            certifiedAt: "Date obtained",
            expiresAt: "Validity",
            certifyTo: "Deadline",
          },
          certificateStatus: "Certificate status",
          downloadAll: "Download all",
        },
        roles: {
          user: "User",
          moderator: "Moderator",
          admin: "Admin",
          user_manager: "User manager",
          content_creator: "Content creator",
        },
      },
      details: {
        editUser: "Edit user",
        inviteUsers: "Invite users",
        userInformation: "User information",
        certification: "Certification",
        trainings: "Trainings",
        password: "Password",
        passwordForm: {
          label: "Reset user password",
          sendEmail: "Send password reset link via email",
          generate: "Generate password reset link",
          resetPassword: "Reset password",
          userDisabledError: "Cannot reset password of deactivated user",
          userNotConfirmedError: "Cannot reset password of not confirmed user",
          userProviderError: "Cannot reset password of SSO based user",
          linkGeneratedMessage: "Password reset link has been generated",
          linkSentMessage: "Password reset link has been sent to the user",
        },
        form: {
          firstName: "First name",
          lastName: "Last name",
          email: "Email",
          groups: "Groups",
          role: "Role",
          dyslexic: "Dyslexic",
          registered: "Registered",
          status: "Status",
          confirmed: "Confirmed",
          deactivated: "Disabled",
          waitingForConfirmation: "Waiting for confirmation",
          lastOnline: "Last online",
          totalPlaytime: "Total playtime",
          resendConfirmation: "Resend confirmation email",
          resendInvitation: "Resend invitation",
          generateLink: "Generate confirmation link",
          invitationSent: "Invitation has been sent",
          confirmationRequestSent: "Confirmation request has been sent",
          confirmationLink: "Confirmation link",
          emailStatus: "Email status",
          minutes: "minutes",
          dialogs: {
            linkConfirmation: {
              header: "Request confirmation link",
              content:
                "Confirmation link allows user to confirm his account and start using Knowingo. Link is unique and confidential. Share it only with the owner of selected account.",
            },
          },
          metaFields: "Meta fields",
          fieldName: "Field name",
          fieldData: "Field data",
        },
      },
      invitation: {
        form: {
          email: "Email address",
          emails: "E-mail addresses",
          groups: "Groups",
          role: "Role",
          addMore: "Add more",
        },
        successMessage: "Invitations have been sent",
        submitButton: "Send",
      },
      import: {
        header: "Import users",
        selectFile: "Select file",
        currentlySelectedFile: "Currently selected file:",
        successMessage: "Upload complete!",
        usersCreated: "Users have been created",
        loadingText: "Uploading data...",
        step1: "Step 1: Select file",
        step2: "Step 2: Select worksheet",
        step3: "Step 3: Verify data",
        step4: "Step 4: Upload",
        headers: {
          email: "Email",
          groups: "Groups",
          firstname: "First name",
          lastname: "Last name",
          disable_after: "Disable after",
        },
        upload: "Upload",
        back: "Back to user list",
      },
    },
    groups: {
      header: "Groups",
      createGroup: "Create group",
      group: "Group",
      editGroup: "Edit group",
      errors: {
        teamNotEmpty: "Team is not empty",
      },
      modals: {
        move: {
          title: "Move groups",
          moving: "Moving",
          newParent: "Select new parent",
          move: "Move",
        },
      },
      details: {
        form: {
          group: "Group",
          description: "Description",
          parent: "Parent",
        },
        groupInformation: "Group information",
        learningPlan: {
          course: "Course",
          label: "Learning plan",
          deadlineOptions: {
            days: "Days",
            weeks: "Weeks",
            months: "Months",
            years: "Years",
          },
          table: {
            title: "Title",
            status: "Status",
            deadline: "Deadline",
            startDate: "Start date",
            occurrences: "Occurrences",
          },
          deadline: "Deadline",
          questions: "questions",
          published: "Published",
          unpublished: "Unpublished",
          delete: "Delete",
          change: "Change",
          complianceContent: "Compliance content",
          mandatoryContent: "Mandatory content",
          optionalContent: "Recommended content",
          indirectTopic: "Inherited topic",
          indirectTopicInfo:
            "This topic is inherited from one of the parents and cannot be changed or removed in this place",
          conflictingTopic: "Conflicting topic",
          conflictingTopicInfo:
            "Deadline or importance of this topic may be overridden by one of the parents.",
          topicPresentInCourse: "Topic already exists",
          topicPresentInCourseInfo:
            "This topic is part of a course assigned to this learning plan",
          noOccurrences: "No occurrences",
          noOccurrencesInfo: "This compliance content has no occurrences set. At least once occurrence is required.",
          accept: "Accept",
          cancel: "Cancel",
          complianceSettings: "Compliance settings",
          startDate: "Start date",
          endDate: "End date",
          cutoffDate: "Don't start after",
          name: "Name",
          addOccurrence: "Add occurrence",
          occurrence: "Occurrence",
          tooltips: {
            compliance: {
              title: "What is compliance?",
              description: "Compliance Topics are required to be completed by users in their set time range. Questions only have to be answered once and will provide a way to audit your userbase.",
              startDate: "Start date",
              startDateDescription: "This is the date where the topic becomes available for the populations",
              endDate: "End date",
              endDateDescription: "This is the deadline for users to reach their compliance date",
              dontStartAfter: "Don't start after",
              dontStartAfterDescription: "This date stops the topic from appearing for users added to the learning plan after this date. This will make it available for them at the next start date.",
            }
          }
        },
        trainings: {
          label: "Trainings",
          indirectTraining: "Indirect training",
          indirectTrainingInfo:
            "This training is inherited from one of the parents",
        },
        settings: {
          groupLeaders: "Group leaders",
          reporting: "Reporting",
          label: "Settings",
          groupRanking: "Group ranking",
          impact: "Impact",
          adoption: "Adoption",
          certification: "Certification",
          enableReporting: "Enable reporting",
          reportType: "Report type",
          reportFormat: "Report format",
          startDate: "Start date",
          hour: "Hour",
          endDate: "End date",
          frequency: "Frequency",
          dayOfTheWeek: "Day of the week",
          azureSelectDescription:
            "Members of selected Azure group will be automatically synchronized during SSO sign-in",
        },
        users: {
          label: "Users",
          table: {
            registered: "Registered",
            invited: "Invited",
            columns: {
              user: "User",
              member: "Member",
              status: "Status",
              actions: "Actions",
            },
            controls: {
              filters: {
                registered: "Registered",
                invited: "Invited",
                member: "Member",
                notMember: "Not a member",
              },
            },
          },
        },
      },
      table: {
        columns: {
          select: "Select",
          group: "Group",
          description: "Description",
          users: "Users",
          topics: "Topics",
          actions: {
            edit: "Edit",
            delete: "Delete",
            openInTab: "Open in new tab",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete group",
              content: "Are you sure you want to delete group {0}?",
              confirmationMessage: "Group has been deleted!",
              dataLostWarning:
                "All data related to this group and all its subgroups, such as group memberships and learning plans, will be lost.",
            },
          },
        },
        controls: {
          createButton: "Create group",
          editGroupButton: "Change settings for {0}",
          actionsButton: "Actions",
          deleteButton: "Delete",
          moveButton: "Move",
          dialogs: {
            deleteConfirmation: {
              header: "Delete groups",
              content: "Are you sure you want to delete selected groups?",
              confirmationMessage: "Selected groups have been deleted!",
              dataLostWarning:
                "All data related to selected groups and all their subgroups, such as group memberships and learning plans, will be lost.",
            },
          },
        },
        tooltips: {
          defaultGroup: {
            header: "Default group",
            content:
              "Default group consists of all users. Members cannot be changed, but you can manage learning plan that will apply to all members of your organization.",
          },
        },
      },
    },
    topics: {
      header: "Topics",
      import: {
        successMessage: "Topic has been added to your library",
        errors: {
          ownTopic: "Topic already exists in your library",
          invalidCode: "Invalid share code",
        },
        form: {
          enterCode: "Enter share code",
        },
      },
      generate: {
        header: "Create AI generated topic",
        form: {
          fields: {
            input: {
              label: "Input",
              placeholder: "What should the topic be about? ",
            },
            numQuestions: {
              label: "Amount of questions",
            },
            numIntroduction: {
              label: "Amount of introduction material",
            },
            baseLanguage: {
              label: "Base language",
            },
            context: {
              label: "Context",
              description:
                "Select file to be used by the model to generate content.",
            },
          },
          questions: "Questions",
          introduction: "Introduction",
          introductionMaterials: "Introduction materials",
          review: "Review",
          advanced: "Advanced",
        },
        generatingContent: "Generating content...",
      },
      details: {
        multiLanguage: {
          details: {
            topic: "Topic",
            localization: "Localization",
            info: "Info",
            createdMessage: "Topic has been created",
            updatedMessage: "Topic has been updated",
          },
          form: {
            useBaseImage: "Use base image",
            title: "Title",
            description: "Description",
            baseLanguage: "Base language",
            certified: "Certified",
            questions: "questions",
            backgroundInfo: "background info",
            introduction: "introduction",
            users: "users",
            selectLanguage: "Select language",
            delete: "Delete",
            tooltips: {
              baseTopic: {
                header: "Base language",
                content: "Base language cannot be removed",
              },
            },
          },
        },
        standard: {
          details: {
            createdMessage: "Topic has been created",
            updatedMessage: "Topic has been updated",
          },
          form: {
            useBaseImage: "Use base image",
            title: "Title",
            description: "Description",
            certified: "Certified",
            questions: "questions",
            backgroundInfo: "background info",
            introduction: "introduction",
            users: "users",
            delete: "Delete",
          },
        },
      },
      modals: {
        clone: {
          header: "Clone topic",
          label: "Enter topic name",
          copy: "copy",
          clone: "Clone",
          cancel: "Cancel",
          enterName: "Enter topic name",
        },
        convert: {
          header: "Convert to multi-language topic",
          description:
            "After conversion users will keep their statistics and certification.",
          label: "Language",
          convert: "Convert",
          cancel: "Cancel",
          selectLanguage: "Select base language",
        },
        publish: {
          header: "Publish topic",
          publish: "Publish",
          cancel: "Cancel",
          multiLanguageTopicInfo:
            "You are about to publish multi-language topic {0}.",
          regularTopicInfo:
            "You are about to publish {0} questions in topic {1}.",
          publishableQuestionsInfo: "{0} questions will be published",
          unpublishableQuestionsInfo:
            "{0} incomplete questions cannot be published",
          contentFetchingError:
            "We couldn't fetch data. Please try again later.",
          publishedNotification: "Topic has been published",
          publishTopic: "You are about to publish topic {0}",
          addMoreQuestions: "Add {0} more questions to publish this topic",
        },
      },
      table: {
        header: "Topics",
        createTopic: "Create topic",
        importTopic: "Import topic",
        editTopic: "Edit topic",
        status: {
          published: "Published",
          publishedDescription:
            "This topic is published and contains {0} questions",
          unpublished: "Unpublished",
          metaUnpublishedDescription:
            "At least one of the translations contains unpublished questions",
          unpublishedDescription:
            "There are {0} unpublished questions in this topic",
          changed: "Unpublished changes",
          metaChangedDescription:
            "At least one of the translations contains unpublished changes",
          changedDescription:
            "There are {0} changed and {1} unpublished questions in this topic",
          shared: "Shared",
          sharedDescription:
            "This topic is shared with you by another organization",
          locked: "Locked",
          lockedDescription: "This topic is locked and cannot be changed",
          users: "Unassigned topic",
          usersDescription:
            "This topic has not been assigned to any learning plan",
          meta: "Multi-language topic",
          metaDescription:
            "This topic is ready to be translated to multiple languages",
          cannotPublish: "Add {0} more questions to publish this topic.",
        },
        controls: {
          filters: {
            published: "Published",
            unpublished: "Unpublished",
            changed: "Unpublished changes",
          },
          actions: {
            label: "Actions",
            publish: "Publish",
            unpublish: "Unpublish",
            delete: "Delete",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete topics",
              content: "Are you sure you want to delete selected topics?",
              successMessage: "Selected topics have been deleted",
            },
          },
          enterShareCode: "Enter share code",
          createTopic: "Create topic",
          createTopicAI: "Create topic with AI",
          createMultiLanguageTopic: "Create multi-language topic",
        },
        components: {
          languageList: {
            tooltip: {
              title: "Localizations",
              description:
                "Add translations to default content to make sure everyone learns the same amount",
              unpublishedQuestions: "{0} unpublished questions",
              changedQuestions: "{0} changed questions",
              noQuestions: "No questions",
              fullyTranslated: "Fully translated",
              translationMissing: "Translation missing",
            },
          },
        },
        columns: {
          select: "Select",
          topic: "Title",
          description: "Description",
          questions: "Questions",
          users: "Users",
          status: "Status",
          thumbnail: "Image",
          comments: "Comments",
          language: "Language",
          actions: {
            openQuestions: "Open questions",
            openIntroduction: "Open introduction material",
            edit: "Edit",
            publish: "Publish",
            unpublish: "Unpublish",
            delete: "Delete",
            clone: "Clone",
            convert: "Convert to multi-language",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete topic",
              content:
                "Are you sure you want to delete topic {0} and all its questions?",
              successMessage: "Topic has been deleted",
            },
          },
        },
        publishedSuccessMessage: "Topic has been published",
        incompleteItemsWarning:
          "Some of the content could not be published due to an error. Please verify whether all the content is complete and try again.",
        unpublishedSuccessMessage: "Topic has been unpublished",
        removedSuccessMessage: "Topic has been removed",
        conversionStarted: "Topic will be converted soon",
        errors: {
          deleteSharedTopic:
            "Cannot delete shared topic. Revoke all existing shares and try again.",
        },
      },
      components: {
        tooltips: {
          status: {
            published: "Published",
            unpublished: "Unpublished",
            changed: "Unpublished changes",
          },
        },
      },
    },
    courses: {
      header: "Courses",
      table: {
        controls: {
          create: "Create course",
          shareCode: "Course share code",
          actions: {
            label: "Actions",
            delete: "Delete",
          },
          filters: {
            published: "Published",
            unpublished: "Unpublished",
          },
        },
        dialogs: {
          deleteConfirmation: {
            header: "Delete courses",
            content: "Are you sure you want to delete selected courses?",
          },
        },
        columns: {
          select: "Select",
          thumbnail: "Image",
          name: "Name",
          description: "Description",
          num_published_topics: "# Published topics",
          languages: "Languages",
          num_topics: "# Topics",
          num_questions: "# Questions",
          num_users: "# Users",
          actions: {
            label: "Actions",
            edit: "Edit",
            delete: "Delete",
          },
        },
      },
      details: {
        header: "Course",
        create: "Create",
        navigation: {
          course: "Course",
          localization: "Localization",
          topics: "Topics",
          info: "Info",
        },
        form: {
          inputs: {
            name: {
              label: "Name",
            },
            description: {
              label: "Description",
            },
            defaultLanguage: {
              label: "Default language",
            },
            courseSettings: {
              label: "Course settings",
              text: "Topic ordering and locking",
            },
          },
        },
        topicsForm: {
          topics: "Topics",
          selectTopic: "Select topic",
          openTopic: "Open topic in new tab",
          showTranslations: "Show translations",
          hideTranslations: "Hide translations",
        },
      },
      dialogs: {
        deleteConfirmation: {
          header: "Delete course",
          content: "Are you sure you want to delete course {0}?",
        },
      },
      tooltips: {
        languageList: {
          translations: "Translations",
        },
        publishedTopics: {
          publishedTopics: "Published topics",
          noPublishedTopics: "This course contains no published topics",
          hasUnpublishedTopics: "This course contains unpublished topics",
        },
      },
    },
    shares: {
      library: {
        header: "Library shares",
        createLibraryShare: "Create library share",
        editLibraryShare: "Edit library share",
        details: {
          form: {
            required: "Topic is required",
            mustBePublished:
              "Topic must be published in order to add it to content library",
            imageRequired:
              "Topic image is required to add it to content library",
            sharingExplanation:
              "Sharing to the content library allows other companies to use your content in their learning plans",
            submissionWarning:
              "Questions, background information and introduction materials will not be editable until the submission is done.",
          },
        },
        table: {
          declined: "declined",
          outdated: "outdated",
          published: "published",
          inReview: "in review",
          columns: {
            select: "Select",
            topic: "Topic",
            holders: "Companies",
            created: "Shared",
            hide_questions: "Hide questions",
            num_users: "Users",
            num_total_certificates: "Certificates earned",
            training_minutes: "Minutes trained",
            status: "Status",
            actions: {
              label: "Actions",
              update: "Update",
            },
          },
          controls: {
            createShare: "Create share",
            actions: {
              label: "Actions",
              updateSelected: "Update selected",
            },
          },
        },
      },
      sharelinks: {
        header: "Sharelinks",
        createShare: "Create share link",
        editShare: "Edit share link",
        table: {
          yes: "Yes",
          no: "No",
          controls: {
            createShare: "Create share",
            actions: {
              label: "Actions",
              revoke: "Revoke",
            },
            dialogs: {
              revokeConfirmation: {
                header: "Revoke share links",
                content:
                  "Are you sure you want to revoke selected share links?",
                successMessage: "Selected share links have been revoked",
              },
            },
          },
          columns: {
            select: "Select",
            topic: "Topic",
            holders: "Companies",
            created: "Shared",
            hide_questions: "Hide questions",
            num_users: "Users",
            num_total_certificates: "Certificates earned",
            training_minutes: "Minutes trained",
            actions: {
              label: "Actions",
              edit: "Edit",
              unhide: "Unhide questions",
              hide: "Hide questions",
              revoke: "Revoke",
            },
            dialogs: {
              revokeConfirmation: {
                header: "Revoke share link",
                content: "Are you sure you want to revoke selected share link?",
                successMessage: "Sharelink has been revoked",
              },
            },
          },
        },
      },
    },
    signupURLs: {
      header: "Signup URLs",
      createUrl: "Create signup URL",
      editUrl: "Edit signup URL",
      details: {
        urlCreated: "Signup URL has been created",
        form: {
          signupURL: "Signup URL",
          groups: "Groups",
        },
      },
      settings: {
        form: {
          domainPlaceholder: "Domain name, e.g. knowingo.com",
          add: "Add",
          enableNotifications: "Enable notifications",
          frequency: "Frequency",
          dayOfTheWeek: "Day of the week",
          dayOfTheWeekDescription:
            "Reports will be automatically sent on the last occurrence of the selected day each month.",
          hour: "Hour",
          allowedDomains: "Allowed domains",
          allowedDomainsDescription:
            "If you enter one or more domains in the provided field, only email addresses associated with those domains will be accepted for sign up. Users with email addresses outside of these specified domains will not be able to access the platform. If the domain list field is left empty, users with email addresses from any domain will be able to sign up without restriction.",
          notifications: "Notifications",
          save: "Save",
          addDomain: "Add domain",
        },
      },
      table: {
        controls: {
          createLink: "Create new link",
          settings: "Settings",
          actions: {
            label: "Actions",
            refresh: "Refresh",
            delete: "Delete",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete signup URLs",
              content: "Are you sure you want to delete selected signup URLs?",
              successMessage: "Selected signup URLs have been deleted",
            },
          },
        },
        columns: {
          code: "Code",
          groups: "Groups",
          created: "Created",
          actions: {
            copyLink: "Copy link",
            edit: "Edit",
            refresh: "Refresh",
            delete: "Delete",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete signup URL",
              content: "Are you sure you want to delete selected signup URL?",
              successMessage: "Signup URL has been deleted",
            },
          },
        },
      },
    },
    feedback: {
      header: "Feedback",
      details: {
        commentDeleted: "Comment deleted",
        cannotDeleteComment: "Couldn't delete comment. Please try again later",
        delete: "Delete",
        status: "Status",
        new: "New",
        resolved: "Resolved",
        closed: "Closed",
        responseSaved: "Response saved",
        yourResponse: "Your response",
        addResponse: "Add response",
        notifyFeedbackAuthor: "Notify feedback author",
      },
      table: {
        filters: {
          new: "New",
          resolved: "Resolved",
          closed: "Closed",
        },
        controls: {
          actions: {
            label: "Actions",
            delete: "Delete",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete feedback",
              content:
                "Are you sure you want to delete selected feedback items?",
              successMessage: "Selected feedback items have ben deleted",
            },
          },
        },
        columns: {
          select: "Select",
          firstname: "Firstname",
          lastname: "Lastname",
          email: "Email",
          title: "Title",
          text: "Text",
          status: "Status",
          created: "Created",
          responses: "Responses",
          actions: {
            label: "Actions",
            delete: "Delete",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete feedback",
              content: "Are you sure you want to delete feedback {0}?",
              successMessage: "Feedback has been deleted",
            },
          },
          deletedUser: "Deleted user",
        },
      },
    },
    questionAnalytics: {
      header: "Question analytics",
      details: {
        components: {
          cards: {
            misconception: "Misconception",
            misconceptionDescription:
              "A misconception occurs when a significant segment of your learners provide the same wrong answer. Use this metric to discover where your learners have major misunderstandings.",
            complexity: "Complexity",
            complexityDescription:
              "Questions are considered complex when your learners take more time than expected to answer them. Learners might consider this question complex if it is too long, contains unknown jargon, or does not belong in this Topic. Edit highly complex questions for better results.",
            obviousness: "Obviousness",
            obviousnessDescription:
              "Questions are considered obvious when your learners are able to answer correctly without any further learning. If Knowingo+ notices that a significant segment of your learners are answering this question correctly the first time they see it, this question’s Obviousness score goes up.",
            error: "Error",
            errorDescription:
              "The error score indicates if the experts seem to disagree with the correct answer to this question. When the majority of experts in a topic pick the same wrong answer, it is extremely likely that there are issues with your question, or that the wrong answer that your experts have chosen is actually the correct one.",
            checkmarkDescription:
              "You can click the checkmark to stop the error score from showing up in the Question Analytics list",
            hideErrorScoreWarning: "Disable error score warning",
          },
        },
        answerContribution:
          "{0} of your user base has chosen {1} to be the correct answer",
        expertsAgree:
          "The majority of experts agree that this is the correct answer",
        expertsContribution: "{0} of experts believe {1} is the correct answer",
        expertsNotAvailable:
          "Expert statistics are not available for this question yet",
        sec: "sec",
        avgAnsweringSpeed: "Average answering speed",
        usersIntroduced: "Users introduced",
        answerStatistics: "Answer statistics",
        openQuestion: "Open question",
      },
      table: {
        published: "Published",
        changed: "Unpublished changes",
        unpublished: "Unpublished",
        incomplete: "Incomplete",
        details: "Details",
        goToQuestion: "Go to question",
        columns: {
          category_name: "Topic",
          title: "Question",
          status: "Status",
          misconception_score: "Misconception",
          complexity_score: "Complexity",
          obviousness_score: "Obviousness",
          content_error_score: "Content error",
          num_users_seen: "Seen by",
          num_users: "Available for",
        },
        controls: {
          showWithoutMetrics: "Show questions without metrics",
          filters: {
            published: "Published",
            unpublished: "Unpublished",
            changed: "Unpublished changes",
            high_misconception: "High misconception",
            high_obviousness: "High obviousness",
            high_complexity: "High complexity",
            high_error_score: "High error score",
          },
          allTopics: "All topics",
          allGroups: "All groups",
          allLanguages: "All languages",
        },
      },
    },
    contentLibrary: {
      header: "Content library",
      grid: {
        questions: "questions",
        backgroundInformation: "background information",
        introductionMaterial: "introduction material",
        owned: "Owned",
        add: "Add",
        languages: "Available languages",
        topicAdded: "Topic has been added onto your library",
      },
    },
    reporting: {
      newReport: "New report",
      table: {
        actions: {
          delete: "Delete",
          run: "Run",
          edit: "Edit",
        },
        controls: {
          actions: {
            label: "Actions",
            delete: "Delete",
            run: "Run",
          },
          create: "Create reporting line",
        },
        columns: {
          name: "Name",
          report: "Report",
          users: "Recipients",
          creator: "Created by",
          frequency: "Frequency",
          enabled: "Enabled",
        },
        filters: {
          ownReports: "My reports",
        },
      },
      dialogs: {
        delete: {
          header: "Delete report",
          content: "Are you sure you want to delete report {0}?",
        },
        deleteMany: {
          header: "Delete selected reports",
          content: "Are you sure you want to delete selected reports?",
        },
      },
      components: {
        allTeams: "All teams",
        allTopics: "All topics",
        selected: "Selected",
        reportFormat: "Report format",
        selectGroups: "Select groups",
        selectCompliance: "Select compliance",
      },
      adoption: "Adoption",
      groupRanking: "Group ranking",
      questionAnalytics: "Question analytics",
      impact: "Impact",
      certificates: "Certificates",
      userDump: "User dump",
      compliance: "Compliance",
      header: "Reporting",
      selectReportType: "Select report type",
      details: {
        reportSettings: "Report settings",
        recipients: "Recipients",
      },
      oneTimeReport: "One time report",
      reoccurringReport: "Reoccurring report"
    },
    settings: {
      account: {
        header: "Account information",
        form: {
          basicInformation: "Basic information",
          companyName: "Company name",
          street: "Street",
          postcode: "Postcode",
          city: "City",
          state: "State/Province",
          country: "Country",
          phone: "Phone",
          vat: "VAT/GST no.",
          contact: "Contact",
          firstName: "First name",
          lastName: "Last name",
          email: "E-mail address",
        },
      },
      general: {
        header: "General settings",
        form: {
          languageSettings: "Language settings",
          description:
            "Language settings apply to new users and can be overwritten in user profile.",
          dashboardLanguage: "Dashboard language",
          dashboardLanguageDescription:
            "Determine default language of user interface.",
          appLanguage: "App language",
          appLanguageDescription:
            "Determine default language of the app and the emails you send.",
        },
      },
      whiteLabeling: {
        header: "White labelling",
        form: {
          companyLogo: "Company logo",
          personalizeParagraph:
            "Personalise Knowingo with your logo. The logo will be featured in the app on different screens with an optional message. In the registration page, dashboard and on different emails.",
          uploadLogoDark: "Upload your logo for dark backgrounds",
          uploadLogoLight: "Upload your logo for light backgrounds",
          additionalText: "Additional logo text",
          additionalTextDescription:
            "Additional logo text will be shown in the Knowingo app to all your employees.",
          additionalEmailText: "Additional email text",
          additionalEmailTextDescription:
            "Additional email text will be included in all invitation and reminder emails sent by Knowingo to your employees.",
        },
        preview: {
          appPreview: "App preview",
          registrationPreview: "Registration preview",
          toggle: "Toggle light and dark mode previews",
        },
      },
      webhooks: {
        header: "Webhooks",
        table: {
          controls: {
            create: "Create webhook",
            shareCode: "Webhook share code",
            actions: {
              label: "Actions",
              delete: "Delete",
            },
            filters: {
              published: "Published",
              unpublished: "Unpublished",
            },
          },
          dialogs: {
            createConfirmation:{
              successMessage: "Webhook has been created"
            },
            updateConfirmation:{
              successMessage: "Webhook has been updated"
            },
            deleteConfirmation: {
              header: "Delete Webhook",
              content: "Are you sure you want to delete selected webhook?",
              successMessage: "Webhook correctly deleted. ",
            },
            notifyConfirmation:{
              successMessage: "Valid URL!",
              invalidUrl: "Invalid URL",
              urlValiderrorMessage: "Valid URL. POST Method not allowed",
              urlValiderrorNotFound: "Url Valid. POST Method Not Found"
            },
          },
          columns: {
            name: "Name",
            event_names: "Event names",
            callback_url: "Callback URL",
            enabled: "Enabled",
            num_failures: "# Failures",
            actions: {
              label: "Actions",
              delete: "Delete",
              edit: "Edit",
              trigger: "Trigger webhook"
            },
          },
        },
        details: {
          webhookCreated: "Webhook has been created",
          webhookUpdated: "Webhook has been updated",
          form: {
            name: "Name",
            eventName: "Event name",
            callbackURL: "Callback URL",
            notifyWebhook: "Try it!",
            invalidUrl: "Invalid URL"
          },
        },
        tooltips: {
          languageList: {
            translations: "Translations",
          },
          publishedWebhooks: {
            publishedWebhooks: "Published Webhooks",
            noPublishedWebhooks: "This course contains no published Webhooks",
            hasUnpublishedWebhooks: "This course contains unpublished Webhooks",
          },
        },
        createWebhook: "Create Webhook",
        editWebhook: "Edit Webhook"
      },
    },
    questions: {
      header: "Questions",
      topics: "Topics",
      questions: "Questions",
      introductionMaterial: "Introduction material",
      createQuestion: "Create question",
      editQuestion: "Edit question",
      modals: {
        copy: {
          header: "Copy questions",
          successMessage: "Questions have been copied",
          metaTopicError:
            "Destination topic must have the same set of languages as source topic",
          save: "Copy",
          cancel: "Cancel",
        },
        move: {
          header: "Move questions",
          successMessage: "Questions have been moved",
          metaTopicError:
            "Destination topic must have the same set of languages as source topic",
          save: "Move",
          cancel: "Cancel",
        },
        addTags: {
          header: "Add tags",
          save: "Save",
          cancel: "Cancel",
        },
        removeTags: {
          header: "Remove tags",
          save: "Save",
          cancel: "Cancel",
        },
        autoTranslate: {
          header: "Auto translate",
          translate: "Translate",
          cancel: "Cancel",
          warning:
            "This action will override existing content in selected questions for selected languages.",
          generatingTranslations: "Generating translations",
          incompleteTranslations:
            "The translating has finished however some content didn't get translated due to an error. Please review these pieces of content and try again.",
        },
      },
      table: {
        status: {
          published: "Published",
          publishedDescription:
            "This question is published and available to play",
          unpublished: "Unpublished",
          unpublishedDescription: "This question is unpublished",
          changed: "Unpublished changes",
          changedDescription: "This question contains unpublished changes",
          incomplete: "Incomplete",
          incompleteDescription:
            "This question is incomplete and cannot be published",
          backgroundInfo: "Background information",
          backgroundInfoDescription:
            "This question contains background information",
          noBackgroundInfoDescription:
            "This question does not contain background information",
        },
        controls: {
          createQuestion: "Create question",
          actions: {
            label: "Actions",
            publish: "Publish",
            unpublish: "Unpublish",
            copy: "Copy",
            move: "Move",
            delete: "Delete",
            addTags: "Add tags",
            removeTags: "Remove tags",
            autoTranslate: "Auto-translate",
          },
          filters: {
            published: "Published",
            unpublished: "Unpublished",
            changed: "Unpublished changes",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete questions",
              content: "Are you sure you want to delete selected questions?",
              successMessage: "Selected questions have been deleted",
            },
          },
        },
        columns: {
          select: "Select",
          thumbnail: "Image",
          question: "Question",
          tags: "Tags",
          status: "Status",
          comments: "Comments",
          backgroundInfo: "Background info",
          actions: {
            edit: "Edit",
            publish: "Publish",
            publishAll: "Publish all translations",
            unpublish: "Unpublish",
            unpublishAll: "Unpublish all translations",
            delete: "Delete",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete question",
              content: 'Are you sure you want to delete question "{0}"',
              successMessage: "Question has been deleted",
            },
          },
        },
      },
      details: {
        components: {
          comments: {
            deletedMessage: "Comment has been deleted",
            savedMessage: "Comment has been saved",
            delete: "Delete",
            yourComment: "Your comment",
            addComment: "Add comment",
          },
        },
        form: {
          useBaseImage: "Use base media",
          question: "Question",
          answers: "Answers",
          tags: "Tags",
          backgroundInformation: "Background information",
          enableBackgroundInformation: "Enable background information",
          majorChange: "Major change",
          majorChangeDescription:
            "Toggle this on and re-publish this question if you believe the changes you made to this question are important enough to reset the statistics associated with this question. This will ensure that even players that have already mastered this question will learn the new changes.",
          lastUpdate: "Last update",
          title: "Title",
          description: "Description",
          comments: "Comments",
          preview: "Preview",
          createdMessage: "Question has been created",
          updatedMessage: "Question has been updated",
          commentsUnavailable:
            "Comments section is not available in shared or locked topics",
        },
        errors: {
          itemChanged:
            "Item changed on a server while editing. Refresh page and submit changes again.",
          problemsOccurred:
            "Problems occurred while saving the following translations:",
        },
      },
      messages: {
        questionPublished: "Question has been published",
        questionsPublished: "Selected questions have been published",
        questionUnpublished: "Question has been unpublished",
        questionsUnpublished: "Selected questions have been unpublished",
        incompleteQuestionsNotPublished:
          "{0} incomplete questions could not be published",
        incompleteQuestionNotPublished:
          "{0} incomplete question could not be published",
      },
    },
    introduction: {
      header: "Introduction",
      topics: "Topics",
      questions: "Questions",
      introductionMaterial: "Introduction material",
      createIntroduction: "Create introduction",
      editIntroduction: "Edit introduction",
      table: {
        status: {
          published: "Published",
          publishedDescription:
            "Introduction is published and available to play",
          unpublished: "Unpublished",
          unpublishedDescription: "Introduction is unpublished",
          changed: "Changed",
          changedDescription: "Introduction contains an unpublished changes",
          incomplete: "Incomplete",
          incompleteDescription:
            "Introduction is incomplete and cannot be published",
        },
        controls: {
          createIntroduction: "Create introduction",
          actions: {
            label: "Actions",
            publish: "Publish",
            unpublish: "Unpublish",
            delete: "Delete",
            autoTranslate: "Auto-translate",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Delete introduction",
              content:
                "Are you sure you want to delete selected introduction materials?",
              successMessage: "Introduction material has been deleted",
            },
          },
          messages: {
            selectedPublished:
              "Selected introduction materials have been published",
            selectedUnpublished:
              "Selected introduction materials have been unpublished",
          },
        },
        messages: {
          published: "Introduction material has been published",
          unpublished: "Introduction material has been unpublished",
        },
        published: "Published",
        changed: "Changed",
        unpublished: "Unpublished",
        incomplete: "Incomplete",
        columns: {
          select: "Select",
          title: "Introduction",
          thumbnail: "Image",
          status: "Status",
          actions: {
            edit: "Edit",
            moveUp: "Move up",
            setIndex: "Set index",
            moveDown: "Move down",
            publish: "Publish",
            unpublish: "Unpublish",
            publishAll: "Publish all translations",
            unpublishAll: "Unpublish all translations",
            delete: "Delete",
          },
          dialogs: {
            replaceIndex: {
              header: "Change index",
              save: "Save",
              successMessage: "Introduction index has been replaced",
            },
            deleteConfirmation: {
              header: "Delete introduction",
              content: 'Are you sure you want to delete introduction "{0}"',
              successMessage: "Introduction material has been deleted",
            },
          },
        },
      },
      details: {
        introduction: "Introduction",
        text: "Text",
        form: {
          useBaseImage: "Use base media",
        },
        createdMessage: "Introduction item has been created",
        updatedMessage: "Introduction item has been updated",
        errors: {
          itemChanged:
            "Item changed on a server while editing. Refresh page and submit changes again.",
          problemsOccurred:
            "Problems occurred while saving the following translations:",
        },
      },
      modals: {
        autoTranslate: {
          header: "Auto translate",
          translate: "Translate",
          cancel: "Cancel",
          warning:
            "This action will override existing content in selected introduction items for selected languages.",
          generatingTranslations: "Generating translations",
          incompleteTranslations:
            "The translating has finished however some content didn't get translated due to an error. Please review these pieces of content and try again.",
        },
      },
      messages: {
        introductionItemPublished: "Introduction item has been published",
        introductionItemsPublished:
          "Selected introduction items have been published",
        introductionItemUnpublished:
          "Selected introduction item has been unpublished",
        introductionItemsUnpublished:
          "Selected introduction items have been unpublished",
        incompleteIntroductionItemsNotPublished:
          "{0} incomplete introduction items could not be published",
        incompleteIntroductionItemNotPublished:
          "{0} incomplete introduction item could not be published",
      },
    },
    certification: {
      header: "Certification",
      table: {
        filters: {
          certified: "Certified",
          onTrack: "On track",
          uncertified: "Uncertified",
          expired: "Expired",
          missed: "Missed deadline",
          mandatory: "Mandatory",
          optional: "Recommended",
        },
        columns: {
          firstName: "Firstname",
          lastName: "Lastname",
          email: "Email",
          status: "Certificate status",
          topic: "Topic",
          isMandatory: "Mandatory",
          deadline: "Deadline",
          certifiedAt: "Date obtained",
          expiresAt: "Validity",
        },
      },
    },
    externalTraining: {
      header: "External training",
      table: {
        controls: {
          actions: {
            label: "Actions",
            delete: "Delete",
            edit: "Edit",
          },
          create: "Create training",
        },
        columns: {
          name: "External training name",
          num_users: "# Users",
          num_invited: "# Users invited",
          num_completed: "# Users completed",
          tags: "Tags",
        },
      },
      details: {
        createTraining: "Create training",
        editTraining: "Edit training",
        form: {
          group: "Group",
          users: "Users",
          noGroupsSelected: "No groups selected",
          inputs: {
            name: {
              label: "Training name",
            },
            groups: {
              placeholder: "Add group",
            },
          },
        },
      },
      dialogs: {
        deleteConfirmation: {
          header: "Delete training",
          content: "Are you sure you want to delete training {0}?",
        },
        deleteManyConfirmation: {
          header: "Delete trainings",
          content: "Are you sure you want to delete selected trainings?",
          dataLostWarning:
            "This action is permanent. All data related to selected trainings will be lost.",
        },
      },
      users: {
        externalTraining: "External training",
        table: {
          controls: {
            actions: {
              label: "Actions",
              invite: "Invite",
              setStatus: "Change status",
            },
            filters: {
              readyForInvite: "Ready for invite",
              invited: "Invited",
              completed: "Completed",
              incomplete: "Incomplete",
            },
            downloadList: "Download list",
          },
          columns: {
            email: "Email",
            start: "Invited for",
            location: "Location",
            status: "Status",
            tags: "Tags",
          },
        },
        modals: {
          update: {
            header: "Update selected",
            selectStatus: "Select status",
          },
        },
        invite: {
          header: "Invite users",
          form: {
            createNewEvent: "Create new event",
            selectExistingEvent: "Select existing event",
            selectEvent: "Select event",
            event: "Event",
            invite: "Invite",
            startDate: "Start date",
            selectStartDate: "Select start date",
            endDate: "End date",
            selectEndDate: "Select end date",
            location: "Location",
            enableCustomInvitationMessage: "Custom invitation message",
            customInvitationMessage: "Custom message for invitation email",
            enableCustomCompletionMessage: "Custom completion message",
            customCompletionMessage: "Custom message for completion email",
            errors: {
              startInPast: "Start date cannot be set in the past",
              endBeforeStart: "End date cannot be set before start date",
            },
          },
        },
      },
    },
    marketplace: {
      header: "Market place",
      bundle: {
        lastPublished: "Last published",
        availableIn: "Available in",
        tags: "Tags",
        getQuote: "Get a quote",
        bundleIncludes: "This bundle includes",
        numCourses: "{0} courses",
        numTopics: "{0} total topics",
        numBackgroundInformation: "{0} background information",
        numIntroductionMaterial: "{0} introduction material",
        numQuestions: "{0} questions",
        continuousSupport: "Continuous Knowingo support",
        certifiedContent: "Knowingo certified content",
        openBundle: "Open bundle",
        owned: "Owned",
        topics: "Topics",
        courses: "Courses",
        quoteModal: {
          header: "Request a quote",
          content:
            "Submitting this will alert the Knowingo sales team to provide a quote. They will contact you to establish the terms of access to this content.",
          confirmation: "Quote request has been sent",
        },
      },
      components: {
        tagFilter: {
          tags: "Tags",
        },
        languageFilter: {
          languages: "Languages",
        },
        contentFilter: {
          generalContent: "General content",
          privateContent: "Private content",
        },
        ownedFilter: {
          bundles: "Bundles",
          all: "All bundles",
          owned: "Only owned bundles",
          not_owned: "Only not owned bundles",
        },
      },
    },
    messages: {
      header: "Messages",
      table: {
        controls: {
          createMessage: "Create message"
        },
        columns: {
          select: "Select",
          subject: "Subject",
          status: "Status",
          recipients: "Recipients"
        },
        scheduledFor: "Scheduled for",
        processing: "Processing",
        sentAt: "Sent at",
        more: "+{0} more"
      },
      details: {
        editMessage: "Edit message",
        createMessage: "Create message",
        now: "Now",
        scheduleFor: "Schedule for",
        notifications: {
          messageSent: "Message has been saved and will be send soon.",
          messageScheduled: "Message has been saved and will be send at: {0}",
        },
        messageSubject: "Message subject",
        subject: "Subject",
        messageBody: "Message body",
        body: "Body",
        recipientGroups: "Recipient groups",
        send: "Send",
        scheduledFor: "Scheduled for",
        language: "Language",
        languageDescription: "Message language is used to determine text formatting in the client app. Language does not affect selected recipients.",
        readonlyWarning: "Messages that have already been sent can no longer be changed."
      },
      dialogs: {
        deleteMessage: {
          header: "Delete message",
          content: "Are you sure you want to delete message {0}? Upon deletion message will not be visible in the in-app inbox."
        },
        deleteMessages: {
          header: "Delete selected messages",
          content: "Are you sure you want to delete selected messages? Upon deletion messages will not be visible in the in-app inbox."
        }
      },
      notifications: {
        messageDeleted: "Message has been deleted",
        messagesDeleted: "Selected messages have been deleted"
      }
    }
  },

  components: {
    header: {
      change: "change",
      tryBetaDashboard: "Try our new beta dashboard",
    },
    trainingStatus: {
      notReady: "Not yet ready for invite",
      ready: "Ready for invite",
      invited: "Invited",
      completed: "Completed",
      incomplete: "Incomplete",
    },
    select: {
      report: {
        reportType: "Report type",
        adoption: {
          name: "Adoption",
          description:
            "The adoption report shows you exactly what the state is of your population in terms of getting them to register and play. This will help you target the different groups of users and give insight on what  makes your adoption process work. The groups in this report are  combined to create a single list of users.",
        },
        group_ranking: {
          name: "Group ranking",
          description:
            "The Group Report helps you identify the people that have learned the most this past month for each group. If any rewards are due, this is how you’re able to figure out who’s been doing their best.",
        },
        question_analytics: {
          name: "Question analytics",
          description:
            "The Question Analytics report shows all the metrics for each question. This will help you determine what kinds of questions work well and what kind of questions are harder to learn from.",
        },
        impact: {
          name: "Impact",
          description:
            "The Knowingo Impact Report highlights what kind of impact Knowingo has had on your users. You will be able to see the increase in knowledge of your population. These increases are shown through Initial Knowledge, Current Knowledge and Potential Knowledge.",
        },
        certificates: {
          name: "Certificates",
          description:
            "The certification report shows the status of users regarding their certificates. You’ll be able to determine what your population’s current state is with their progress.",
        },
        user_dump: {
          name: "User dump",
          description:
            "The report contains different data about user certification, knowledge and groups.",
        },
        group_certificates: {
          name: "Group certificates",
          description:
            "The certification report shows the status of users regarding their certificates. You’ll be able to determine what your population’s current state is with their progress.",
        },
        certificates_postnl: {
          name: "Certificates (PostNL)",
          description:
            "The certification report shows the status of users regarding their certificates. You’ll be able to determine what your population’s current state is with their progress.",
        },
        compliance: {
          name: "Compliance",
          description:
            "The compliance report shows data collected from users that have compliance topics assigned to them. This will help you to assess your populations compliance levels.",
        },
      },
      group: {
        selectGroup: "Select group",
        loading: "Loading",
        noResults: "No search results for {0}",
      },
      tags: {
        selectTags: "Select tags",
        successMessage: "Tag {0} has ben created",
        createOption: "Create tag",
      },
      company: {
        selectCompany: "Select company",
      },
      language: {
        selectLanguage: "Select language",
        en: "English",
        nl: "Dutch",
        de: "German",
        fr: "French",
        ru: "Russian",
        ar: "Arabic",
        pt: "Portuguese",
        es: "Spanish",
        cs: "Czech",
        he: "Hebrew",
        ur: "Urdu",
        th: "Thai",
        zh: "Chinese",
        hi: "Hindi",
        it: "Italian",
        pl: "Polish",
      },
      topic: {
        selectTopic: "Select topic",
        selectTopics: "Select topics",
      },
      content: {
        placeholder: "Select content",
        course: "Course",
        topic: "Topic",
        publishedQuestions: "published questions",
      },
      compliance: {
        selectCompliance: "Select compliance",
      },
      user: {
        notConfirmed: "Not confirmed",
        selectUser: "Select user",
      },
      webhook:{
        placeholder: "Select Event",
      },
      timezone: {
        timezone: "Timezone",
      },
    },
    clipboard: {
      successMessage: "Copied to clipboard",
      errorMessage: "Could not copy. Please copy selected text manually",
    },
    spanDropdown: {
      day: "Days",
      week: "Weeks",
      month: "Months",
      quarter: "Quarters",
      year: "Years",
    },
    comments: {
      deletedMessage: "Comment has been deleted",
      savedMessage: "Comment has been saved",
      delete: "Delete",
      yourComment: "Your comment",
      addComment: "Add comment",
    },
    sidebar: {
      avatar: {
        alt: "User avatar",
        roles: {
          admin: "Admin",
          moderator: "Moderator",
        },
      },
      items: {
        dashboard: "Dashboard",
        users: "Users",
        groups: "Groups",
        topics: "Topics",
        courses: "Courses",
        certification: "Certification",
        accountInfo: "Account information",
        companySettings: "Settings",
        whiteLabeling: "White labelling",
        webHooks: "WebHooks",
        documentation: "Documentation",
        moderatorCompanies: "Companies",
        contentSharing: "Content sharing",
        questionAnalytics: "Question analytics",
        feedback: "Feedback",
        shareOverview: "Share overview",
        myProfile: "My profile",
        signupURLs: "Signup URLs",
        logout: "Log out",
        newTag: "New!",
        proTag: "PRO",
        shareLink: "Sharelink",
        library: "Library",
        disabledItemNotice:
          "Company moderator cannot see this item because feature is disabled",
        contentLibrary: "Content library",
        reporting: "Reporting",
        activityLog: "Activity log",
        enterpriseStats: "Enterprise stats",
        externalTraining: "External training",
        marketplace: "Marketplace",
        messages: "Messages",
      },
    },
    table: {
      controls: {
        filterDropdown: {
          filterBy: "Filter by",
          clearFilters: "Clear filters",
          filters: {
            sent: "Sent",
            scheduled: "Scheduled",
          }
        },
        actionsDropdown: {
          label: "Actions",
          delete: "Delete selected"
        },
        searchBox: {
          search: "Search",
        },
      },
      actions: {
        delete: "Delete"
      },
      noSearchResults: "No search results for: {0}",
      noData: "No data",
      moreItems: "{0} more items. Click here to open all items in a new tab.",
    },
    charts: {
      week: "Week",
      quarter: "Quarter",
    },
    submissionConfirmation: {
      successMessage: "Saved!",
    },
    statusIcon: {
      published: "Published",
      unpublished: "Unpublished",
      changed: "Changed",
      incomplete: "Incomplete draft",
    },
    cropper: {
      errors: {
        unsupportedFileType: "Unsupported file type",
      },
      buttons: {
        upload: "Upload",
        delete: "Delete",
        confirm: "Confirm",
      },
    },
    form: {
      buttons: {
        save: "Save",
        loading: "Loading",
        actionAndClose: "{0} and close",
      },
      fields: {
        repeatSelect: {
          every: "Every",
          days: "Days",
          weeks: "Weeks",
          months: "Months",
          daily: "Daily",
          weekly: "Weekly",
          monthly: "Monthly",
          custom: "Custom",
        },
        weekdaySelect: {
          mon: "Mon",
          tue: "Tue",
          wed: "Wed",
          thu: "Thu",
          fri: "Fri",
          sat: "Sat",
          sun: "Sun",
        },
      },
      validators: {
        required: "Field is required",
        invalidUrl: "Not a valid URL",
      },
    },
    richTextEditor: {
      errors: {
        textTooLong: "Entered text is too long",
      },
      toolbox: {
        bold: "Bold",
        italic: "Italic",
        underline: "Underline",
        strikethrough: "Strikethrough",
        superscript: "Superscript",
        subscript: "Subscript",
        link: "Link",
        fontSize: "Font size",
        fontColor: "Font color",
        highlightColor: "Highlight color",
        clearFormatting: "Clear formatting",
      },
    },
    downloader: {
      title: "Download",
      generatingFile: "Requested file is being generated. Please wait...",
      fileReady:
        "Your file is ready! Click on a button below to save it on your computer.",
      loading: "Loading",
      download: "Download",
      close: "Close",
    },
    confirm: {
      confirm: "Confirm",
      cancel: "Cancel",
    },
    certification: {
      unknown: "Unknown",
      certified: "Certified",
      on_track: "On track",
      expiring: "Expiring",
      expired: "Expired",
      overdue: "Missed deadline",
      uncertified: "Uncertified",
    },
    mediaComponent: {
      type: "Type",
      text: "Text",
      image: "Image",
      video: "Video",
      videoUrl: "Video URL",
      thumbnail: "Thumbnail",
      videoPreview: "Video preview",
      videoWarning: "Unable to load video",
    },
    topicInfo: {
      assignedGroups: "Assigned groups",
      assignedCourses: "Assigned courses",
      noAssignedGroups: "No assigned groups",
      noAssignedCourses: "No assigned courses",
      topicOwner: "Topic owner",
    },
    datePicker: {
      hour: "Hour",
      minutes: "Minutes",
      date: "Date"
    }
  },

  inputs: {
    startDate: {
      label: "Start date",
    },
    endDate: {
      label: "End date",
    },
    frequency: {
      label: "Frequency",
    },
    weekday: {
      label: "Weekday",
    },
    includeDisabledUsers: {
      label: "Include disabled users",
    },
    includeMetaFields: {
      label: "Include meta fields",
    },
    ignoreMinAnswers: {
      label: "Include questions without metrics",
      warning:
        "Some metrics concerning Question Analytics might not be reliable due to a low exposure to players.",
    },
    name: {
      label: "Name",
    },
    repeatSelect: {
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
    },
    enabled: {
      label: "Enabled",
      description:
        "You can uncheck this field to suspend sending emails to selected recipients.",
    },
    monthSelect: {
      label: "Select month",
    },
    filters: {
      name: "Name",
      created: "Created",
      updated: "Updated",
      published: "Published",
      num_questions: "# Questions",
      num_topics: "# Topics",
      num_courses: "# Courses",
      num_tips: "# Tips",
      num_theories: "# Introduction",
    },
    noFiltersAvailable: "No filters available",
    url: {
      placeholder: "Enter URL",
    }
  },

  errors: {
    resourceUpdated:
      "Resource has been updated while performing the action. Refresh page and try again.",
    topicIsShared:
      "Cannot delete shared topic. Revoke all existing shares and try again.",
    tryAgain: "Try again",
  },

  errorBoundary: {
    updateAvailableHeader: "Update available",
    updateAvailableContent:
      "There is new version of the Knowingo dashboard available. Click on a button below to reload the page.",
    reloadButton: "Reload",
    somethingWentWrongHeader: "Oops... Something went wrong",
    somethingWentWrongContent:
      "It looks like we are having some internal issues. Our team has been notified.",
    pleaseReload:
      "Please reload the page and try again. If the issue persists please contact support at {0}",
    eventIdentifier: "Event identifier",
  },

  form: {
    errors: {
      start_in_past: "Start date cannot be set to in the past",
      end_before_start: "End date cannot be set to before start date",
      "Missing data for required field.": "Missing data for required field",
      "Length must be between 1 and 35.": "Length must be between 1 and 35",
      "Length must be between 3 and 120.": "Length must be between 3 and 120.",
      unknownError: "Unknown error",
      "Not a valid URL.": "Not a valid URL",
      "Invalid domain": "Invalid email domain.",
    },
  },

  // OLD
  loginPage: {
    htmlTitle: "Knowingo | Login",
    header: "Sign in to Knowingo{0}",
    emailAddress: "Email address",
    password: "Password",
    rememberMe: "Remember me",
    loginBtn: "Sign in",
    loginWithSSOBtn: "Sign in using SSO",
    forgotPassword: "Forgot Password?",
    goBack: "Go back",
    here: "here",
    inputs: {
      domain: {
        placeholder: "Domain",
      },
    },
    errors: {
      invalidCredentials: "Provided email address or password is incorrect.",
      disabledAccount:
        "Your account is disabled. Contact support@knowingo.com for more information.",
      deletedAccount:
        "Your account is deleted. Contact support@knowingo.com for more information.",
      tooManyAttempts:
        "Too many failed login attempts. Try again in 5 minutes.",
      permissionError:
        "Logging in allowed only for company moderators. Download the Knowingo+ app and play on your mobile device. Contact support@knowingo.com for more information.",
      cookiesError:
        "It seems your browser blocks thirdparty cookies. Please enable them for this site in your browser security settings.",
      invalidEmail: "Invalid email address",
      invalidPassword: "Invalid password",
      notConfirmed:
        "Your account is not confirmed. Please check your email address and click on activation link",
      ssoAccount:
        "Your account is connected to different login provider. Please use SSO as login method.",
      companyAccessDenied: "You don't have access to this company.",
      emailNotConfirmed:
        "Check your mailbox and confirm email address before logging in. Click here to resend email with confirmation link.",
      noSeats: "Company has no seats available",
      alreadyLoggedIn: "Already logged in. Click here to open dashboard.",
      unknownError: "Unknown error",
    },
    confirmationRequestSent: "Email with confirmation link has been sent",
    successLogin: "Logged in!",
  },
  notFound: {
    header: "Not found",
    textCompany:
      "It seems like entered company domain does not exist in Knowingo. Please verify entered URL.",
  },
  ssoLoginPage: {
    header: "Select SSO provider",
    errors: {
      ssoNotSupported:
        "Single Sign-On is not supported by your organization. Please contact your administrator for details.",
    },
  },
  domainPage: {
    errors: {
      companyNotFound: "Company not found. Please check entered domain name.",
    },
  },
  forgotPassword: {
    htmlTitle: "Knowingo | Forgot password",
    formHeader: "Reset password",
    formEmail: "Email",
    formSubmit: "Reset password",
    backButton: "Back to login",
    success: "Please check your email",
    errors: {
      invalidEmail: "Invalid email address",
      alreadyLoggedIn: "Already logged in",
      userNotFound: "User not found",
      unknownError: "Unknown error",
    },
    inputs: {
      email: {
        placeholder: "Email address",
      },
    },
  },
  dashboard: {
    htmlTitle: "Knowingo | Dashboard",
    toggleNavigation: "Toggle navigation",
    welcome: "Hi {0}",
  },
  pageNotFound: "Page not found",
  overview: {
    title: "Dashboard",
  },
  activityLog: {
    columns: {
      activity: "Activity",
      created: "Created",
      user: "User",
    },
  },
};

module.exports = { en };
