import { BaseModel } from "./base";

export const AdminReportTypes = {
  user_dump: "user_dump",
};

export const CompanyReportTypes = {
  adoption: "adoption",
  group_ranking: "group_ranking",
  question_analytics: "question_analytics",
  impact: "impact",
  certificates: "certificates",
  group_certificates: "group_certificates",
};

export const CustomReportTypes = {
  certificates_postnl: "certificates_postnl",
};

export const FeatureReportTypes = {
  compliance: "compliance",
}

export const AllReportTypes = {
  ...AdminReportTypes,
  ...CompanyReportTypes,
  ...CustomReportTypes,
  ...FeatureReportTypes
};

export const getAvailableReports = (roles, company) => {
  const {custom_reports: customReports, features} = company;

  let reports = [
    ...Object.keys(CompanyReportTypes),
    ...customReports.filter((it) => CustomReportTypes[it]),
  ];

  if (roles.includes("admin")) {
    const adminReports = [...Object.keys(AdminReportTypes)];
    reports = [...reports, ...adminReports];
  }

  Object.keys(FeatureReportTypes).forEach((feature) => {
    if (features[feature]) {
      reports = [...reports, FeatureReportTypes[feature]];
    }
  })

  return reports;
};

export class AdoptionReport extends BaseModel {
  constructor({
    fmt = "xlsx",
    teams = [],
    include_disabled = false,
    include_meta = false,
  } = {}) {
    super(arguments);

    this.fmt = fmt;
    this.teams = teams;
    this.include_disabled = include_disabled;
    this.include_meta = include_meta;
  }

  get teams_ids() {
    return this.teams.map((it) => it.id);
  }
}

export class GroupCertificatesReport extends BaseModel {
  constructor({
    fmt = "xlsx",
    group = null,
    include_disabled = false,
    include_meta = false,
  } = {}) {
    super(arguments);

    this.fmt = fmt;
    this.group = group;
    this.include_disabled = include_disabled;
    this.include_meta = include_meta;
  }

  get group_id() {
    return this.group?.id || null;
  }
}

export class GroupRankingReport extends BaseModel {
  constructor({
    fmt = "xlsx",
    teams = [],
    date = null,
    include_meta = false,
  } = {}) {
    super();

    this.fmt = fmt;
    this.teams = teams;
    this.date = date;
    this.include_meta = include_meta;
  }

  get teams_ids() {
    return this.teams.map((it) => it.id);
  }
}

export class QuestionAnalyticsReport extends BaseModel {
  constructor({ fmt = "xlsx", group = null, categories = [], min_users_seen = null, ignore_min_answers = null } = {}) {
    super();

    this.fmt = fmt;
    this.group = group;
    this.categories = categories;

    this.ignore_min_answers = ignore_min_answers || min_users_seen === 0;
    this.min_users_seen = min_users_seen || ignore_min_answers ? 0 : undefined;
  }

  get group_id() {
    return this.group?.id || null;
  }

  get categories_ids() {
    return this.categories.map((it) => it.id);
  }
}

export class ImpactReport extends BaseModel {
  constructor({ fmt = "xlsx", teams = [], categories = [] } = {}) {
    super();

    this.fmt = fmt;
    this.teams = teams;
    this.categories = categories;
  }

  get teams_ids() {
    return this.teams.map((it) => it.id);
  }

  get categories_ids() {
    return this.categories.map((it) => it.id);
  }
}

export class CertificatesReport extends BaseModel {
  constructor({
    fmt = "xlsx",
    teams = [],
    categories = [],
    include_disabled = false,
    include_meta = false,
  } = {}) {
    super();

    this.fmt = fmt;
    this.teams = teams;
    this.categories = categories;
    this.include_disabled = include_disabled;
    this.include_meta = include_meta;
  }

  get teams_ids() {
    return this.teams.map((it) => it.id);
  }

  get categories_ids() {
    return this.categories.map((it) => it.id);
  }
}

export class UserDumpReport extends BaseModel {
  constructor({
    fmt = "xlsx",
    include_disabled = false,
    include_meta = false,
  } = {}) {
    super();

    this.fmt = fmt;
    this.include_disabled = include_disabled;
    this.include_meta = include_meta;
  }
}


export class ComplianceReport extends BaseModel {
  constructor({
    fmt = "xlsx",
    include_meta = false,
    compliance = null
  } = {}) {
    super();

    this.fmt = fmt;
    this.include_meta = include_meta;
    this.compliance = compliance;
  }

  get compliance_id() {
    return this.compliance?.id;
  }
}

export const getReportClass = (reportType) => {
  switch (reportType) {
    case AllReportTypes.adoption:
      return AdoptionReport;
    case AllReportTypes.group_ranking:
      return GroupRankingReport;
    case AllReportTypes.question_analytics:
      return QuestionAnalyticsReport;
    case AllReportTypes.impact:
      return ImpactReport;
    case AllReportTypes.certificates:
      return CertificatesReport;
    case AllReportTypes.user_dump:
      return UserDumpReport;
    case AllReportTypes.group_certificates:
      return GroupCertificatesReport;
    case AllReportTypes.certificates_postnl:
      return CertificatesReport;
    case AllReportTypes.compliance:
      return ComplianceReport;
    default:
      return null;
  }
};

export class ScheduledReport extends BaseModel {
  constructor({ report_type = null, report_kwargs = {} } = {}) {
    super();

    const reportTypeClass = getReportClass(report_type);

    this.report_type = report_type;
    this.report_kwargs = reportTypeClass
      ? new reportTypeClass(report_kwargs)
      : report_kwargs;
  }
}
