import { paginationActions, requestActions } from "./utils";

export const TAGS = requestActions("Tags/List");
export const PAGINATION = paginationActions("Tags/Paginate");

export const TAGS_ALL = requestActions("Tags/ListAll");
export const PAGINATION_ALL = paginationActions("Tags/ListAll/Paginate");

export const TAGS_DEFAULT = requestActions("Tags/ListDefault");
export const PAGINATION_DEFAULT = paginationActions(
  "Tags/ListDefault/Paginate",
);
