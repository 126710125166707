import { paginationActions, requestActions } from "./utils";

export const COURSES = requestActions("Courses/List");
export const COURSES_DELETE = requestActions("Courses/List/Delete");
export const PAGINATION = paginationActions("Courses/Paginate");
export const SELECT = "Courses/Select";
export const SELECT_ALL = "Courses/Select/All";
export const SHOW_COLUMNS = "Courses/ShowColumns";
export const COURSE = requestActions("Course/Get");
export const COURSE_DELETE = requestActions("Course/Delete");
export const COURSE_SUBMIT = requestActions("Course/Submit");
export const COURSE_RESET = "Course/Reset";
export const COURSE_UPDATE = requestActions("Course/Update");
export const COURSE_TOPICS = requestActions("Course/Topics/List/Get");
export const COURSE_TOPICS_UPDATE = requestActions("Course/Topics/List/Submit");
export const COURSE_TOPICS_PAGINATION = requestActions(
  "Course/Topics/List/Paginate",
);
export const COURSE_TOPICS_ADD = "Course/Topics/List/Add";
export const COURSE_TOPICS_REMOVE = "Course/Topics/List/Remove";
export const COURSE_TOPICS_SET_ORDER = "Course/Topics/List/SetIndex";
export const COURSE_TOPICS_SET_ORDERING = "Course/Topics/List/SetOrdering";