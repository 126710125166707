import classNames from "classnames";
import React from "react";
import strings from "l10n";

import styles from "./button.module.scss";

const Button = React.forwardRef(
  (
    {
      children,
      icon,
      type = "submit",
      accent = "primary",
      onClick,
      className,
      size,
      active,
      iconPlacement = "inside",
      loading,
      disabled,
      ...props
    },
    ref,
  ) => {
    const handleClick = (event) => {
      if (onClick) {
        event.preventDefault();
        onClick(event);
      }
    };

    return (
      <button
        ref={ref}
        type={type}
        className={classNames(
          styles.button,
          styles[`button-accent-${accent}`],
          styles[`icon-${iconPlacement}`],
          className,
          size ? styles[`btn-${size}`] : "",
          { "overflow-visible": !children },
          {
            [styles.active]: active,
          },
        )}
        onClick={handleClick}
        disabled={disabled || loading}
        {...props}
      >
        {icon && <i className={classNames(`far fa-${icon}`)} />}

        {loading ? (
          <span>
            <i className="fas fa-circle-notch fa-spin" />{" "}
            {strings.buttons.loading}
          </span>
        ) : children ? (
          React.isValidElement(children) ? (
            children
          ) : (
            <span>{children}</span>
          )
        ) : null}
      </button>
    );
  },
);

export default Button;
