import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Multiselect as MultiselectBase } from "react-widgets";

import styles from "./multiSelect.module.scss";

const Multiselect = (props) => {
  //  Original Multiselect component open/close actions are a bit wonky and caret icon is not visible.
  //  We override default icon and handle open/hide ourselves

  const [open, setOpen] = useState(false);
  const elRef = useRef();
  const { disabled } = props;

  useEffect(() => {
    //  Detect whether user clicked outside of the dropdown component
    const _handleClickOutside = ({ target }) => {
      if (!open || !elRef.current) {
        return;
      }
      let clickedInside = false;
      let toIterate = [...elRef.current.children];

      while (toIterate.length) {
        const el = toIterate.shift();
        if (el.children.length) {
          toIterate = [...toIterate, ...el.children];
        }
        if (el === target) {
          clickedInside = true;
          break;
        }
      }

      if (!clickedInside) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", _handleClickOutside);
    return () =>
      document.body.removeEventListener("click", _handleClickOutside);
  });

  return (
    <div
      className={styles.multiselectWrapper}
      ref={elRef}
      onClick={() => (open || disabled ? null : setOpen(true))}
    >
      <MultiselectBase {...props} selectIcon={<></>} open={open} />

      <div
        className={styles.caretIconWrapper}
        onClick={() => (disabled ? null : setOpen(!open))}
      >
        <i className={classNames("fas fa-caret-down")} />
      </div>
    </div>
  );
};
export default Multiselect;
